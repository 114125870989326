import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _auth: AngularFireAuth) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(environment.urlBaseApi)) {
      return this._auth.idToken.pipe(
        take(1),
        switchMap((token) => {
          if (token) {
            request = this.injectToken(request, token)
          }
          return next.handle(request);
        })
      )
    } else {      
      return next.handle(this.injectTokenWithouAuth(request))
    }
  }

  injectToken(request: HttpRequest<any>, newToken) {
    return request.clone({
      headers: request.headers.set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${newToken}`)
    });
  }

  injectTokenWithouAuth(request: HttpRequest<any>) {
    return request.clone({
      headers: request.headers.set('Content-Type', 'application/json')
    });
  }
}
