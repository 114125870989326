import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionEnum } from 'src/app/enums/permission-enum';
import { AuthService } from 'src/app/services/auth.service';
import FilterUtil from 'src/app/util/filter-util';
import NumberUtil from 'src/app/util/number-util';
import StaticParams from 'src/app/util/static-params';

@Component({
  selector: 'app-ativo-noticias',
  templateUrl: './ativo-noticias.component.html',
  styleUrls: ['./ativo-noticias.component.scss']
})
export class AtivoNoticiasComponent implements OnInit {

  @Input() stockinfo: any = {}
  @Input() portfolio: any = {}

  public currencySymbols = StaticParams.currencySymbols
  public news = []
  public events = []
  public proventos = []
  public hasUserNewsPermission = false

  public newsPage: number = 1;
  public newsPageSize: number = 10;
  public newsCollectionSize: number;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.hasUserNewsPermission = this._authService.hasUserPermission(PermissionEnum.NEWS)
    this.news = this.stockinfo.news ? this.sortByDate(this.stockinfo.news, 'date') : []
    this.events = this.stockinfo.events && this.stockinfo.events.stockDividends ? this.sortByDate(this.stockinfo.events.stockDividends, 'lastDatePrior') : []
    this.proventos = this.stockinfo.events && this.stockinfo.events.cashDividends ? this.sortByDate(this.stockinfo.events.cashDividends, 'lastDatePrior') : []
    this.newsCollectionSize = this.news.length
  }

  goToAlphaPage() {
    this._router.navigate(['/alpha']);
    this.activeModal.close();
  }

  convertToUsNumber(number) {
    return NumberUtil.usFormat(number)
  }

  sortByDate(earningsArray, orderField: string) {
    earningsArray.forEach(earning => {
      const dateToSort = earning[orderField]
      if (dateToSort) {
        earning.sort_date = earning[orderField].split('/').reverse().join('-')
      } else {
        earning.sort_date = earning['approvedOn'].split('/').reverse().join('-')
      }
    });

    return FilterUtil.lodash().orderBy(earningsArray, ['sort_date'], ['desc'])
  }
}
