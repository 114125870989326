import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export default class DateUtil {
    static ngbDatePickerToString(date: NgbDateStruct) {
        return date.year + "-" + DateUtil.addTrailingZero(date.month) + "-" + DateUtil.addTrailingZero(date.day)
    }

    static addTrailingZero(day_or_month) {
        return ('00' + day_or_month).slice(-2);
    }

    static dateStringToNgbDatePicker(date: any) {
        date = date.split('-')
        date = { year: parseInt(date[0]), month: parseInt(date[1]), day: parseInt(date[2]) }
        return date
    }

    static usStrDatetoBrStrDate(date: string) {
        return date.split('-').reverse().join('/');
    }

    static brToUsFormat(date: string) {
        return date.split('/').reverse().join('-');
    }

    static ordinalWeekDay(year: number, month: number, ordinal: number, weekday: string) {
        let d = new Date(year, month, 1)
        d.setUTCHours(0, 0, 0, 0)

        const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].findIndex(x => x === weekday)

        const ORDINALCONST = 7 * ordinal + dayOfWeek - d.getUTCDay() - (d.getUTCDay() <= dayOfWeek ? 7 : 0)
        d.setUTCDate(d.getUTCDate() + ORDINALCONST)

        return DateUtil.toUTCDateString(d)
    }

    static dateToString(date: any) {
        if (this.isValidDate(date)) {
            return date.toLocaleDateString('en-GB').split("/").reverse().join("-")
        } else {
            date = date.toString()
            date = date.split(' ')[0]

            if (date.indexOf('000Z') > 0) {
                return date.split('T')[0]
            }

            let dateBr = date.split('/').length == 3
            if (dateBr) {
                date = date.split('/')
                date = date[2].length == 4 ? `${date[2]}-${date[1]}-${date[0]}` : `20${date[2]}-${date[1]}-${date[0]}`
                if (date.length == 10) { return date }
            }

            let dateUs = date.split('-').length == 3
            if (dateUs) {
                date = date.split('-')
                date = date[0].length == 4 ? `${date[0]}-${date[1]}-${date[2]}` : `20${date[0]}-${date[1]}-${date[2]}`
                if (date.length == 10) { return date }
            }

            let excelDate = parseInt(date)
            if (excelDate) {
                date = this.toUTCDateString(this.excelDateToJSDate(date))
                if (!date.includes('Invalid')) { return date }
            }

            return null
        }
    }

    static isValidDate(date) {
        return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
    }

    static toUTCDateString(date) {
        const timeDiff = date.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(date.valueOf() + timeDiff);
        return adjustedDate.toLocaleDateString('pt-br').split('/').reverse().join('-');
    }

    static excelDateToJSDate(date) {
        return new Date(Math.round((date - 25569) * 86400 * 1000));
    }
}