import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { PermissionEnum } from '../enums/permission-enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private _afAuth: AngularFireAuth
  ) { }

  public setSession(authResult: any, newWalletId: string = null) {
    authResult['date'] = new Date().getTime()

    if (newWalletId) {
      localStorage.setItem('walletId', newWalletId)
    } else {
      if (!localStorage.getItem('walletId')) {
        localStorage.setItem('walletId', authResult.carteiras[0].id)
      }
    }

    localStorage.setItem('usuario', JSON.stringify(authResult))
  }

  public isAlphaUser() {
    const user = this.getDecodedJwt();

    if (user.alpha) {
      const dataVencimento = new Date(user.alpha.data_vencimento);
      const agora = new Date();
      if (dataVencimento > agora) {
        return user.alpha.status
      }
    }

    return false;
  }

  public getUserPlan() {
    const user = this.getDecodedJwt()

    if (user.alpha) {
      return user.alpha.cd_plano
    }

    return null
  }

  public hasUserPermission(permission: PermissionEnum) {
    if (this.isAlphaUser() && this.getUserPlan() != null) {
      const user = this.getDecodedJwt();
      return user.alpha.permissoes[permission]
    }

    return false
  }

  public isLoggedIn() {
    return (localStorage.getItem('usuario') !== null) && (localStorage.getItem('usuario') !== "");
  }

  // Get info user local storage
  public getDecodedJwt() {
    return JSON.parse(localStorage.getItem('usuario'))
  }

  public getMoedaBase() {
    return this.getDecodedJwt().moeda_base
  }

  public isToGroupFxAsset() {
    return this.getDecodedJwt().in_group_fx_asset
  }

  public getBasicUserInfo() {
    return {
      'email': localStorage.getItem('email'),
      'name': localStorage.getItem('name'),
      'photo': localStorage.getItem('photo')
    }
  }

  public setUserBasicInfo(user: firebase.User) {
    localStorage.setItem('email', user.email);
    localStorage.setItem('name', user.displayName);
    localStorage.setItem('photo', user.photoURL);
  }

  public logout() {
    this._afAuth.signOut().then(() => {
      localStorage.clear()
      window.open('/login', '_self')
    })
  }
}
