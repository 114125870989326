import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ViewOptionsService } from 'src/app/services/view-options.service';

export const modalConfirmationDialogOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-confirmation-dialog' };

@Component({
  selector: 'app-modal-confirmation-dialog',
  templateUrl: './modal-confirmation-dialog.component.html',
  styleUrls: ['./modal-confirmation-dialog.component.scss']
})
export class ModalConfirmationDialogComponent implements OnInit {

  public isDarkMode = false
  public modalProps: any = {};

  constructor(
    private _viewOptionsService: ViewOptionsService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);

    this.modalProps.title = this.modalProps.title || "Confirmação"
    this.modalProps.message = this.modalProps.message.split("\n").join("<br>")
    this.modalProps.prompt = "";
  }

  buttonOk() {
    this.modalProps.type = true;
    this.activeModal.close(this.modalProps);
  }

  buttonCancel() {
    this.modalProps.type = false;
    this.activeModal.close(this.modalProps);
  }

}
