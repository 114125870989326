import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DynamicStyleService } from 'src/app/services/dynamic-style.service';
import { WalletService } from 'src/app/services/wallet.service';
import { DOCUMENT } from "@angular/common";
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmationDialogComponent, modalConfirmationDialogOptions } from '../modal-confirmation-dialog/modal-confirmation-dialog.component';
import { UserService } from 'src/app/services/user.service';
import DateUtil from 'src/app/util/date-util';
import StorageUtil from 'src/app/util/storage-util';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';
import { AuthService } from 'src/app/services/auth.service';

export const modalMultiInvestidorOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-multi-investidor' };

@Component({
  selector: 'app-modal-multi-investidor',
  templateUrl: './modal-multi-investidor.component.html',
  styleUrls: ['./modal-multi-investidor.component.scss']
})
export class ModalMultiInvestidorComponent implements OnInit {

  public user: any = {}
  public investidorWallets: any = []
  public deleteLoading = false

  constructor(
    private _modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public dynamicCss: DynamicStyleService,
    private _walletService: WalletService,
    private _toastr: ToastrService,
    private _userService: UserService,
    private _router: Router,
    private _cacheService: CacheService,
    private _authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('usuario')) || {}
    this.investidorWallets = this.user.carteiras || []
  }

  isActiveInvestidorWallet(investidorWalletId) {
    let localActiveId = StorageUtil.getActiveWalletName().id
    return investidorWalletId == localActiveId
  }

  createNewInvestidor() {
    if (!StorageUtil.isValidInvestidorLimit()) {
      this._toastr.warning('Limite de investidores do plano atingido! Verifique seu plano em "Minha Conta"');
      this._router.navigate(['/alpha']);
      this.activeModal.close()
      return
    }
    let newInvestidor = {
      id: null,
      nome: `Investidor ${this.investidorWallets.length + 1}`,
    }

    this._walletService.createInvestidor(newInvestidor).subscribe({
      next: async (res) => {
        this.investidorWallets = res
        this.user.carteiras = res
        localStorage.setItem('usuario', JSON.stringify(this.user))
      }
    });
  }

  editInvestidor(i) {
    this.investidorWallets[i].edit = !this.investidorWallets[i].edit
  }

  cancelEditInvestidor(i) {
    this.user = JSON.parse(localStorage.getItem('usuario')) || {}
    this.investidorWallets[i] = this.user.carteiras[i] || []
  }

  saveInvestidor(i) {
    this._walletService.editInvestidor(this.investidorWallets[i]).subscribe({
      next: async (res) => {
        this.investidorWallets = res
        this.user.carteiras = res
        localStorage.setItem('usuario', JSON.stringify(this.user))
      }
    })
    this.investidorWallets[i].edit = !this.investidorWallets[i].edit
  }

  selectInvestidor(i) {
    this._cacheService.clearCacheOnInvestidorChange()

    this._userService.setWalletId(this.investidorWallets[i].id)
    this._userService.getSettingsUserAPI().subscribe({
      next: (userSettings) => {
        this.user.moeda_base = userSettings.moeda_base
        this.user.calc_por_corretora = userSettings.calc_por_corretora
        this._authService.setSession(this.user, this.investidorWallets[i].id)
      },
      error: (err) => { }
    })

    setTimeout(() => {
      this.document.location.reload()
      this.document.location.href = '/carteira'
    }, 2000);
    this._toastr.success('Aguarde enquanto recarregamos a página!');
  }

  confirmDeleteInvestidor(i) {
    const modalAlert = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions)
    modalAlert.componentInstance.modalProps = {
      type: 'confirm',
      message: `ATENÇÃO!<br>
      É recomendável salvar um backup antes de continuar!
      Deseja salvar um backup local dos dados?`,
      okText: 'Sim',
      cancelText: 'Não'
    }
    modalAlert.result.then((result) => {
      if (result.type) {
        this.getCurrentJsonFromServer(this.investidorWallets[i])
      }

      const modalRef = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions);
      modalRef.componentInstance.modalProps = {
        type: 'prompt',
        message: `ATENÇÃO!<br>
          O investidor e todas seus dados serão excluidos.
          Essa operação é irreversível.<br>
          Para confirmar escreva "${this.investidorWallets[i].nome}" e clique em Confirmar`,
        okText: 'Confirmar',
        cancelText: 'Cancelar'
      }
      modalRef.result.then((result) => {
        if (result.type && result.prompt == this.investidorWallets[i].nome) {
          this.deleteLoading = i
          this._walletService.delInvestidor(this.investidorWallets[i]).subscribe({
            next: async (res) => {
              this.investidorWallets = res
              this.user.carteiras = res
              localStorage.setItem('usuario', JSON.stringify(this.user))
              this.deleteLoading = false
            },
            error: (err) => {
              this.deleteLoading = false
            }
          });
        } else if ((result.type && result.prompt != this.investidorWallets[i].nome)) {
          this._toastr.error('Texto digitado não confere!');
        }
      });

    });
  }

  deleteInvestidorDefault(i) {
    this._toastr.warning('Não é possível excluir o investidor principal ou selecionado.');
  }

  //  <!-- Backup JSON local -->
  getCurrentJsonFromServer(investidorWallet) {
    this._userService.getBackupJsonById(investidorWallet.id).subscribe({
      next: (res) => {
        this.download(JSON.stringify(res), `backup-${investidorWallet.nome}-${DateUtil.dateToString(new Date())}.json`, 'text/plain');
      },
      error: (err) => { }
    })
  }

  download(content, fileName, contentType) {
    let a = document.createElement("a");
    let file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

}
