import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseApiService {

  constructor(
    protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService
  ) {
    super(baseApi, router, toastr);
  }

  getUser(): Observable<any> {
    return this.get(this.urlBaseApi + `/usuario`);
  }

  getUserWithWallet(): Observable<any> {
    return this.get(this.urlBaseApi + `/usuario/wid/${this.walletId}`);
  }

  getSettingsUserAPI(): Observable<any> {
    return this.get(this.urlBaseApi + `/usuario/config/wid/${this.walletId}`);
  }

  updateSettingsUserAPI(userSetting: any): Observable<any> {
    userSetting.tributavel_normal = - Math.abs(userSetting.tributavel_normal)
    userSetting.tributavel_daytrade = - Math.abs(userSetting.tributavel_daytrade)
    userSetting.tributavel_fii = - Math.abs(userSetting.tributavel_fii)
    userSetting.darf_total = Math.abs(userSetting.darf_total)
    return this.put(this.urlBaseApi + `/usuario/config/wid/${this.walletId}`, userSetting, false);
  }

  updateTermsOfUse(): Observable<any> {
    return this.put(this.urlBaseApi + '/usuario/termosuso', '');
  }

  getBackupJsonCurrent() {
    return this.get(this.urlBaseApi + `/backup/current/wid/${this.walletId}`);
  }

  getBackupJsonById(walletId) {
    return this.get(this.urlBaseApi + `/backup/current/wid/${walletId}`);
  }

  removeAccount(): Observable<any> {
    return this.delete(this.urlBaseApi + '/usuario/excluir', '');
  }

  getUserAdmin(): Observable<any> {
    return this.get(this.urlBaseApi + `/usuario/isadmin`);
  }

  setWalletId(walletId: string) {
    this.walletId = walletId;
  }

  updateTheme(darkMode: boolean): Observable<any> {
    return this.put(this.urlBaseApi + '/usuario/darkmode', { "option": darkMode });
  }

  updateShowDetails(showDetails: boolean): Observable<any> {
    return this.put(this.urlBaseApi + '/usuario/showdetails', { "option": showDetails });
  }

  updateShowCurrency(showCurrency: boolean): Observable<any> {
    return this.put(this.urlBaseApi + '/usuario/showcurrency', { "option": showCurrency });
  }

  updateGroupFxAsset(groupFxAsset: boolean): Observable<any> {
    return this.put(this.urlBaseApi + '/usuario/groupfxasset', { "option": groupFxAsset });
  }

  updateTour(): Observable<any> {
    return this.put(this.urlBaseApi + '/usuario/tour', '');
  }

  createExternalToken(): Observable<any> {
    return this.post(this.urlBaseApi + `/externaltoken/wid/${this.walletId}`, '');
  }

  getExternalToken(): Observable<any> {
    return this.get(this.urlBaseApi + `/externaltoken/wid/${this.walletId}`);
  }

  removeExternalToken(): Observable<any> {
    return this.delete(this.urlBaseApi + `/externaltoken/wid/${this.walletId}`, '');
  }

  getEmailConfig(): Observable<any> {
    return this.get(this.urlBaseApi + `/usuario/email/config`);
  }

  updateEmailConfig(emailConfig): Observable<any> {
    return this.put(this.urlBaseApi + `/usuario/email/config`, emailConfig);
  }

  listUserBackupsFromCloud(): Observable<any> {
    return this.get(this.urlBaseApi + `/backup/cloud/list/wid/${this.walletId}`);
  }

  getUserBackupFromCloud(backupName: string): Observable<any> {
    return this.get(this.urlBaseApi + `/backup/cloud/wid/${this.walletId}/${backupName}`);
  }
}
