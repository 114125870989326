import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CategoriaEnum } from 'src/app/enums/categoria-enum';
import { OutrosService } from 'src/app/services/outros.service';
import { ViewOptionsService } from 'src/app/services/view-options.service';
import StaticParams from 'src/app/util/static-params';
import { ModalSimpleOperationComponent, modalSimpleOperationsOptions } from '../modal-simple-operation/modal-simple-operation.component';

export const modalSimpleAddOutrosOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-simple-operation-dialog' };

@Component({
  selector: 'app-modal-simple-add-outros',
  templateUrl: './modal-simple-add-outros.component.html',
  styleUrls: ['./modal-simple-add-outros.component.scss']
})

export class ModalSimpleAddOutrosComponent {

  public newAsset: any = {}
  public btnLoading: boolean = false
  public isDarkMode: boolean = false
  public newAssetClassOptions: any = StaticParams.outros().classe
  public newAssetSetorOptions: any = StaticParams.outros().setor
  public newAssetSubsetorOptions: any = StaticParams.outros().subsetor
  public categoriaEnum = CategoriaEnum
  public categoriaSelecionada: string
  public outrosAtivos: Array<any>
  public operationRow: any = {}
  public isFromOperationsModal: boolean = true
  public isUpdatingAsset: boolean = false

  constructor(
    public activeModal: NgbActiveModal,
    private _toastr: ToastrService,
    private _outrosService: OutrosService,
    private _viewOptionsService: ViewOptionsService,
    private _modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);

    if (this.categoriaSelecionada == CategoriaEnum.rfixa) {
      this.newAsset.classe = "R.FIXA"
      this.newAsset.in_auto_update = true
      this.newAssetSetorOptions = StaticParams.outros().rfixa
      this.newAssetSubsetorOptions = StaticParams.outros().index
    } else {
      this.newAsset.in_auto_update = false
    }
  }

  async closeModal(ignoreDataForOperation: boolean = false, reloadAssets: boolean = false) {
    if (this.isFromOperationsModal) {
      this.activeModal.close()
      const modalRef = this._modalService.open(ModalSimpleOperationComponent, modalSimpleOperationsOptions);
      modalRef.componentInstance.categoriaSelecionada = this.categoriaSelecionada;
      modalRef.componentInstance.operationRow = this.operationRow

      if (!ignoreDataForOperation) {
        this.operationRow.ativo = this.newAsset.ativo
        modalRef.componentInstance.selectedOtherInfo = this.newAsset
      }

      await modalRef.componentInstance.handleCategories(true)
    } else {
      this.activeModal.close(reloadAssets)
    }
  }

  addOutroAtivo() {
    this.btnLoading = true
    const data = new Array()

    if (this.newAsset.ativo == null) {
      this._toastr.error('Defina um nome para o ativo!')
      this.btnLoading = false
      return false
    }

    this.newAsset.ativo = this.newAsset.ativo.trim().toUpperCase().split('  ').join(' ')

    if (this.outrosAtivos && !this.isUpdatingAsset) {
      const hasAssetWithSameName = this.outrosAtivos.find(ativo => ativo.ativo == this.newAsset.ativo)
      if (hasAssetWithSameName) {
        this._toastr.error('Já existe um ativo cadastrado com o mesmo nome!')
        this.btnLoading = false
        return false
      }
    }
    
    data.push(this.newAsset)

    this._outrosService.addOutros(data).subscribe({
      next: async (res) => {
        if (this.isUpdatingAsset) {
          this._toastr.success('Ativo atualizado com sucesso');
        } else {
          this._toastr.success('Ativo criado com sucesso');
        }
        
        this.btnLoading = false
        this.closeModal(false, true)
      },
      error: (err) => {
        this.btnLoading = false
      }
    })
  }

  handleIndexes() {
    if (this.categoriaSelecionada == CategoriaEnum.rfixa && ['PRE', 'IPCA'].includes(this.newAsset.subsetor)) {
      this.newAsset.index_percentage = null
    }
  }

  resetNewAsset() {
    this.newAssetClassOptions = StaticParams.outros().classe
    this.newAssetSetorOptions = StaticParams.outros().setor
    this.newAssetSubsetorOptions = StaticParams.outros().subsetor

    this.newAsset = {
      "ativo": null,
      "classe": null,
      "setor": null,
      "subsetor": null,
      "in_auto_update": null,
      "index_percentage": null,
      "annual_additional_percentage": null
    }
  }
}
