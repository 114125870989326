import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import StorageUtil from 'src/app/util/storage-util';
import { ROUTES } from '../sidebar/sidebar.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus: any;
  public listTitles: any[];
  public userInfo: any;
  public autoOpenModal: boolean = false;

  constructor(
    private _location: Location,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    let nomeDoInvestidor = StorageUtil.getActiveWalletName().nome
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.userInfo = this._authService.getBasicUserInfo();
    this.userInfo.photo = this.userInfo.photo == 'null' ? 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-512.png' : this.userInfo.photo
    this.userInfo.name = nomeDoInvestidor == 'Default' && this.userInfo.name != 'null' ? this.userInfo.name : nomeDoInvestidor
    
    this._activatedRoute.queryParams.subscribe(params => {
      if (params['email_config'] && JSON.parse(params['email_config'])) {
        this.autoOpenModal = true
      }
    })
  }

  getTitle() {
    var title = this._location.prepareExternalUrl(this._location.path());

    for (var item = 0; item < this.listTitles.length; item++) {
      if (title.startsWith(this.listTitles[item].path)) {
        return this.listTitles[item].title;
      }
    }

    return title.split('/').join('')
  }

}
