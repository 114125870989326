import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  date: Date = new Date();
  
  constructor(
  ) { }

  ngOnInit() { 
    this.addClassBodyHtml();
  }

  ngOnDestroy() {
    let html = document.getElementsByTagName("html")[0];
    html.classList.remove("auth-layout");
    let body = document.getElementsByTagName("body")[0];
    body.style.display = 'block'
    body.classList.remove("bg-default");
  }

  addClassBodyHtml() {
    let html = document.getElementsByTagName("html")[0];
    html.classList.add("auth-layout");
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
  }
}
