import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  protected http: HttpClient;
  protected authToken: any;
  protected urlBaseApi = environment.urlBaseApi
  protected environment = environment;
  protected walletId: string;

  constructor(
    protected httpClient: HttpClient,
    protected router: Router,
    protected toastr: ToastrService
  ) {
    this.http = httpClient;
    this.walletId = localStorage.getItem('walletId');
  }

  protected get(endpoint: string, showToastrWhenTimeout: boolean = true): Observable<any> {
    return this.http.get(endpoint)
      .pipe(catchError(this.errorHandler.bind(this, showToastrWhenTimeout)));
  }

  protected post(endpoint: string, data: any): Observable<any> {
    return this.http.post(endpoint, JSON.stringify(data))
      .pipe(catchError(this.errorHandler.bind(this)));
  }

  protected put(endpoint: string, data: any, showToastrWhenTimeout: boolean = true): Observable<any> {
    return this.http.put(endpoint, JSON.stringify(data))
      .pipe(catchError(this.errorHandler.bind(this, showToastrWhenTimeout)));
  }

  protected delete(endpoint: string, body: any = null, showToastrWhenTimeout: boolean = true): Observable<any> {
    let options = this.getHttpOptions();
    if (body != null) {
      options['body'] = JSON.stringify(body);
    }
    return this.http.delete(endpoint, options)
      .pipe(catchError(this.errorHandler.bind(this, showToastrWhenTimeout)));
  }

  protected file(endpoint: string): Observable<any> {
    let options = this.getHttpOptions();
    options['responseType'] = 'text';
    return this.http.get(endpoint, options)
      .pipe(catchError(this.errorHandler.bind(this)));
  }

  private errorHandler(error: any, showToastrWhenTimeout: boolean = true) {
    let responseError: any;

    if (error.error instanceof ErrorEvent) {
      // client-side error
      responseError = { code: error.code, message: error.error.message };
    } else {
      // server-side error
      responseError = { code: error.status, message: error.message };
    }

    switch (responseError.code) {
      case 401:
        this.toastr.error('Usuário não autenticado. Faça seu login novamente!')
        localStorage.clear()
        window.open('/login', '_self')
        break
      case 503:
        this.router.navigate(['/manutencao']).then(() => {
          window.location.reload()
        })
        break
      case 402:
        this.toastr.error(error.error.messages);
        this.router.navigate(['/alpha'])
        break
      case 524:
        if (showToastrWhenTimeout) {
          this.toastr.error('Tempo limite excedido. Favor, tentar novamente em instantes.');
        }

        break
      default:
        if (error?.error?.messages) {
          this.toastr.error(error.error.messages);
        } else {
          this.toastr.error('Erro no servidor, tente novamente ou contate o suporte!');
        }
    }

    return throwError(() => responseError);
  }

  private getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }
}