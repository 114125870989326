import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class OutrosService extends BaseApiService {

  constructor(
    protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService
  ) {
    super(baseApi, router, toastr);
  }

  addOutros(data: any): Observable<any> {
    return this.put(this.urlBaseApi + `/outros/wid/${this.walletId}`, data);
  }

  delOutros(data: any): Observable<any> {
    return this.delete(this.urlBaseApi + `/outros/wid/${this.walletId}`, data);
  }

  getOutros(): Observable<any> {
    return this.get(this.urlBaseApi + `/outros/wid/${this.walletId}`);
  }

  addOutrosFromBackup(othersData: any): Observable<any> {
    othersData.forEach(other => {
      other.ativo = other.ativo.trim().toUpperCase().split('  ').join(' ')
      other.classe = other.classe.trim().toUpperCase()
      other.setor = other.setor ? other.setor.trim().toUpperCase() : null
      other.subsetor = other.subsetor ? other.subsetor.trim().toUpperCase() : null
    });

    return this.put(this.urlBaseApi + `/outros/import/wid/${this.walletId}`, othersData);
  }

}
