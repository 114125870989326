export default class NumberUtil {

    static usFormat(num: any) {
        if (!num) { return 0 }

        num = num.toString().toUpperCase().indexOf("E-") >= 0 ? parseFloat(num).toFixed(10) : num.toString().replace(/[^0-9.,-]/g, '')
        let decimalSeparator = num.replace(/[0-9]/g, '').split('').reverse()[0] || null

        if (decimalSeparator == ',') {
            num = num.split(".").join("").replace(",", ".")
        } else {
            num = num.split(",").join("")
        }
        return parseFloat(num)
    }

    static brFormat(num: any) {
        if (!num) { return 0 }

        num = num.toString().toUpperCase().indexOf("E-") >= 0 ? parseFloat(num).toFixed(10) : num.toString().replace(/[^0-9.,-]/g, '')
        let decimalSeparator = num.replace(/[0-9]/g, '').split('').reverse()[0] || null

        if (decimalSeparator == ',') {
            num = num.split(".").join("").replace(",", ".")
        } else {
            num = num.split(",").join("")
        }
        return parseFloat(num).toString().replace(".", ",")
    }

    static trunc(num: number, decimal: number) {
        let value = num.toString().split('.')
        let dec = value[1] || ''
        if (dec && decimal) {
            dec = dec.substring(0, decimal)
            dec = '.' + dec
        }
        return parseFloat(value[0] + dec)
    }

    static roundTo(value: number, decimal: number) {
        return Math.round((value + Number.EPSILON) * decimal) / decimal
    }

    static percentStringToNumber(value: any) {
        if (value == '' || !value && value != 0) { return null }
        value = value.toString().replace(',', '.')
        if (value.indexOf('%') >= 0) {
            value = (parseFloat(value.split('%').join('').split(' ').join('').replace(',', '.')) / 100)
        } else if (parseFloat(value) >= 1) {
            value = parseFloat(value) / 100
        } else {
            value = parseFloat(value)
        }
        return this.roundTo(value, 10000)
    }

    static isNumeric(text: string): boolean {
        const regex = /^[0-9.-]+$/;
        return regex.test(text);
    }
}

