import * as _ from 'lodash';
import * as cryptoJS from 'crypto-js';

export default class FilterUtil {

    static cryptoJS() {
        return cryptoJS
    }
    static MD5(string) {
        const hash = this.cryptoJS().MD5(this.cryptoJS().enc.Utf8.parse(string));
        const md5 = hash.toString(this.cryptoJS().enc.Hex)
        return md5.toString();
    }
    static SHA256(string) {
        const hash = this.cryptoJS().HmacSHA256(this.cryptoJS().enc.Utf8.parse(string), '')
        const sha256 = hash.toString(this.cryptoJS().enc.Hex)
        return sha256.toString();
    }

    static lodash() {
        return _
    }

    static dlpFilter(ObjectArray: Array<Object>, filterParams: Object) {
        let filterKeys = Object.keys(filterParams)
        let result = []
        let filtred = []
        let parcial = null

        let hasValidFilter = false
        for (let i in filterKeys) {
            hasValidFilter = filterParams[filterKeys[i]] && filterParams[filterKeys[i]].length ? true : hasValidFilter
        }
        if (!hasValidFilter) { return ObjectArray }

        for (let i in filterKeys) {
            let key = filterKeys[i]
            if (filterParams[key]) {
                let filterValue = filterParams[key]
                if (filterValue.length) {
                    filtred = []
                    result = []
                    for (let j in filterValue) {
                        filtred = _.filter(parcial || ObjectArray, (row) => {
                            let parametro = filterValue[j].toString().toUpperCase()
                            if (typeof row[key] == 'object') {
                                let res = false
                                let linha = row[key] ? row[key].toString().toUpperCase().split(',') : []
                                linha.forEach(el => { if (el == parametro) { res = true } })
                                return res
                            }
                            let linha = row[key] ? row[key].toString().toUpperCase() : row[key]
                            return (linha == parametro)
                        })
                        result = result.concat(filtred)
                    }
                    parcial = result
                }
            }
        }

        result = this.lodash().uniq(result)
        return result
    }

    static groupBy(listaEventos: any, agrupadores: any, totalizadores: any) {
        // const agrupadores = ['ativo', 'evento', 'classe']
        // const totalizadores = ['valorLiq', 'vlrLiq', 'vlrBruto', 'irrf']

        if (!listaEventos) {
            return listaEventos;
        }

        const helper = {};
        const chavesInteresse = agrupadores.concat(totalizadores);
        const proventosOrganizados = listaEventos.reduce((resultado, atual) => {
            let novaChave = '';
            agrupadores.forEach((chave) => {
                novaChave = novaChave + atual[chave];
            });

            const todasChaves = Object.keys(atual);
            const chavesRemover = todasChaves.filter((chave) => {
                return chavesInteresse.indexOf(chave) < 0;
            });

            if (!helper[novaChave]) {
                helper[novaChave] = atual || [];

                chavesRemover.forEach((chave) => {
                    delete helper[novaChave][chave];
                });

                resultado.push(helper[novaChave]);
            } else {
                totalizadores.forEach((chave) => {
                    helper[novaChave][chave] =
                        parseFloat(helper[novaChave][chave]) + parseFloat(atual[chave]);
                });
            }

            return resultado;
        }, []);

        return proventosOrganizados;
    }

    static filterObjectBy(objectArray: any, filterKeys: any) {
        // let filterKeys = [
        //     { field: 'ativo', value: "PETR4" },
        //     { field: 'corretora', value: "INTER" }
        //   ] 
        filterKeys = filterKeys || [];
        if (filterKeys.length) {
            filterKeys.forEach((key) => {
                objectArray = objectArray.filter((item) => {
                    if (key.comparator) {
                        return this.compararPorOperador(item[key.field], key.value, key.comparator);
                    }
                    return item[key.field] == key.value;
                });
            });
        }
        return objectArray;
    }

    static sortObjectBy(objectArray: any, sortKeys: any) {
        //  let sortKeys = [
        //     { field: 'date', order: 'asc', type: 'date' },
        //     { field: 'ativo', order: 'asc', type: 'string' },
        //     { field: 'id', order: 'asc', type: 'number' },
        //     { field: 'qtd', order: 'desc', type: 'number' }
        //   ]
        sortKeys = sortKeys || [];
        if (sortKeys.length) {
            objectArray.sort((a: any, b: any) => {
                let result = null;
                sortKeys.forEach((key) => {
                    result = result || FilterUtil.compararObjetosPorTipoEOrdem(a[key.field], b[key.field], key.type, key.order);
                });
                return result;
            });
        }
        return objectArray;
    }

    static compararObjetosPorTipoEOrdem(a: any, b: any, type: any, order: any) {
        if (type == 'date' && order == 'asc') {
            return a - b;
        } else if (type == 'date' && order == 'desc') {
            return b - a;
        } else if (type == 'number' && order == 'asc') {
            return a - b;
        } else if (type == 'number' && order == 'desc') {
            return b - a;
        } else if (type == 'string' && order == 'asc') {
            return a.localeCompare(b);
        } else if (type == 'string' && order == 'desc') {
            return b.localeCompare(a);
        }
    }

    static compararPorOperador(a: any, b: any, operator: any) {
        if (operator == '>') {
            return a > b;
        } else if (operator == '<') {
            return a < b;
        } else if (operator == '>=') {
            return a >= b;
        } else if (operator == '<=') {
            return a <= b;
        }
        return a == b;
    }

    static distincValuesOnObjectList(array: Array<Object>, indexName: string): Array<Object> {
        return array.map(item => item[indexName])
            .filter((value, index, self) => self.indexOf(value) === index)
    }

    static getDropDownSettings() {
        return {
            itemsShowLimit: 1,
            singleSelection: false,
            selectAllText: '(Selecionar Tudo)',
            unSelectAllText: '(Deselecionar Tudo)',
            allowSearchFilter: true,
            searchPlaceholderText: 'Procurar',
            clearSearchFilter: false,
        }
    }
}