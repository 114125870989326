import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as jspreadsheet from 'jspreadsheet-ce';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EarningsService } from 'src/app/services/earnings.service';
import { StockService } from 'src/app/services/stock.service';
import { SummaryService } from 'src/app/services/summary.service';
import DateUtil from 'src/app/util/date-util';
import EventUtil from 'src/app/util/event.util';
import FilterUtil from 'src/app/util/filter-util';
import NumberUtil from 'src/app/util/number-util';
import StaticParams from 'src/app/util/static-params';
import StorageUtil from 'src/app/util/storage-util';
import { ModalConfirmationDialogComponent, modalConfirmationDialogOptions } from '../modal-confirmation-dialog/modal-confirmation-dialog.component';

export const modalEarningsOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-earnings' };

@Component({
  selector: 'app-modal-earnings',
  templateUrl: './modal-earnings.component.html',
  styleUrls: ['./modal-earnings.component.scss']
})
export class ModalEarningsComponent {

  @ViewChild('spreadsheet') spreadsheet: ElementRef;

  public title = ''
  public closeText = ''
  public data: any = [{}];
  public loading = false

  public table: any;
  public slicedData = []
  public pagesDataLen = 0
  public saveDataPage = 0
  public isEditingRows = 0
  public isLoadingBackup: boolean = false

  constructor(
    private _serviceEarnings: EarningsService,
    private _toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private _summaryService: SummaryService,
    private _router: Router,
    private _modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    private _stockService: StockService
  ) { }

  ngOnInit(): void {
    this.title = this.title ? this.title : this.data[0]?.id ? "Editar Proventos" : "Adicionar Proventos"
    this.closeText = this.closeText || "Fechar"

    this.isEditingRows = this.data[0]?.id ? 10 : 0
    this.data = this.removeCalculatedPropetys(this.data)

    this.slicedData = this.sliceIntoChunks(this.data, 5000)
    this.pagesDataLen = this.slicedData.length
  }

  sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  removeCalculatedPropetys(proventos) {
    let result = []
    for (let i in proventos) {
      let { ativo, corretora, date, date_com, evento, irrf, moeda, valor, observacao, id } = proventos[i]
      if (this.isEditingRows) {
        result.push({ ativo, corretora, date, date_com, evento, irrf, moeda, valor, observacao, id })
      } else {
        result.push({ ativo, corretora, date, date_com, evento, irrf, moeda, valor, observacao })
      }
    }
    return FilterUtil.lodash().sortBy(result, ['ativo', 'corretora', 'date'])
  }

  ngAfterViewInit() {
    this.table = jspreadsheet(this.spreadsheet.nativeElement, {
      data: this.data,
      columns: this.getBrowserModel(),
      minDimensions: [
        this.data[0]?.length ? this.data[0]?.length : 9,
        this.isEditingRows ? this.data?.length : 15,
      ],
      allowManualInsertColumn: false,
      allowDeleteColumn: false,
      columnResize: true,
      allowManualInsertRow: this.isEditingRows ? false : true,
      allowDeleteRow: this.isEditingRows ? false : true,
    });
    this.table.setData(this.slicedData[this.saveDataPage])
  }

  getBrowserModel() {
    let result = []
    result = [
      {
        title: '*Ativo',
        width: 110,
        name: 'ativo',
        type: "text",
      },
      {
        title: '*Data',
        width: 80,
        name: 'date',
        // type: "calendar",
        type: 'text',
      },
      {
        title: "*Evento",
        name: 'evento',
        type: "dropdown",
        width: 130,
        source: EventUtil.earningEvents(),
        autocomplete: true,
      },
      {
        title: '*Valor',
        width: 80,
        name: 'valor',
        decimal: ',',
      },
      {
        title: 'Irrf',
        width: 80,
        name: 'irrf',
        decimal: ',',
      },
      {
        title: 'Moeda',
        name: 'moeda',
        type: "dropdown",
        width: 80,
        source: Object.keys(StaticParams.currencySymbols),
        autocomplete: true,
      },
      {
        title: '*Corretora',
        width: 100,
        name: 'corretora',
        type: "text",
      },
      {
        title: 'Data Com',
        width: 80,
        name: 'date_com',
        type: "text",
      },
      {
        title: "Observação",
        width: 120,
        name: 'observacao',
        type: "text",
      },
    ]

    let browserName = StaticParams.fnBrowserDetect()
    if ('firefox,safari'.split(',').indexOf(browserName) >= 0) {
      result[1].type = "calendar"
      result[5].width = 90
      result[8].width = 110
    }
    else if ('chrome,opera'.split(',').indexOf(browserName) >= 0) {
      result[1].mask = 'dd/mm/yyyy'
      result[7].mask = 'dd/mm/yyyy'
    }

    if (this.isEditingRows) {
      result.push({
        title: "Id",
        width: 100,
        name: 'id',
        type: "hidden",
      })
    }

    return result
  }

  async save() {
    if (!StorageUtil.isValidInvestidorPlan()) {
      this._toastr.warning('Limite de investidores do plano atingido! Verifique seu plano em "Minha Conta"');
      this._router.navigate(['/alpha']);
      this.activeModal.close()
      return
    }

    this.loading = true
    this.spinnerService.show()
    const earnings = this.isValidEarningsObject(this.spreadsheet.nativeElement.jexcel.getJson())

    if (earnings.error.length == 0 && earnings.valid.length > 0 && !this.isEditingRows) {
      this._toastr.info('Processando. Aguarde!')
      let calculate_historic_and_portfolio: boolean = true
      if (this.isLoadingBackup) {
        calculate_historic_and_portfolio = false
      }
      this._serviceEarnings.addEarnings(earnings.valid, calculate_historic_and_portfolio).subscribe({
        next: (x) => {
          this._toastr.success('Proventos adicionados')

          if (!this.isLoadingBackup) {
            this._summaryService.updateSummaryBehavior()
          }
          
          this.loading = false
          this.spinnerService.hide()
          this.saveDataPage++
          if (this.saveDataPage == this.pagesDataLen) {
            this.activeModal.close(1)
          } else {
            this.table.setData(this.slicedData[this.saveDataPage])
          }
        },
        error: (err) => {
          this.loading = false
          this.spinnerService.hide()
        }
      })
    }
    else if (earnings.error.length == 0 && earnings.valid.length > 0 && this.isEditingRows) {
      this._toastr.info('Processando. Aguarde!')
      this._serviceEarnings.editList(earnings.valid).subscribe({
        next: (x) => {
          this._toastr.success('Proventos editados')
          this._summaryService.updateSummaryBehavior()
          this.loading = false
          this.spinnerService.hide()
          this.saveDataPage++
          if (this.saveDataPage == this.pagesDataLen) {
            this.activeModal.close(1)
          } else {
            this.table.setData(this.slicedData[this.saveDataPage])
          }
        },
        error: (err) => {
          this.loading = false
          this.spinnerService.hide()
        }
      })
    }
    else if (earnings.error.length == 0 && earnings.valid.length == 0) {
      this._toastr.error('Preencha os campos obrigatórios!')
      this.loading = false
      this.spinnerService.hide()
    } else {
      this.loading = false
      this.spinnerService.hide()
    }
  }

  isValidEarningsObject(earningsObject) {
    const result = {
      valid: [],
      error: [],
    }
    for (let i in earningsObject) {
      let reqFields = earningsObject[i].ativo && earningsObject[i].date && earningsObject[i].corretora
      let row = this.isValidEarningRow(earningsObject[i])
      if (!row.error && reqFields) {
        result.valid.push(row)
      } else if (row.error && reqFields) {
        result.error.push(earningsObject[i])
        this._toastr.error(`Erro na Linha [${parseInt(i) + 1}]: ${row.error}`)
      }
    }
    return result
  }

  isValidEarningRow(earningRow) {
    let ReqFields = earningRow.ativo && earningRow.date && earningRow.evento && earningRow.corretora
    let today = new Date();
    today.setMonth(today.getMonth() + 3)

    if (ReqFields) {
      earningRow.ativo = earningRow.ativo.trim().toUpperCase().split('  ').join(' ')
      earningRow.corretora = StaticParams.verificaCorretora(earningRow.corretora)
      earningRow.moeda = earningRow.moeda || 'BRL'
      earningRow.valor = NumberUtil.usFormat(earningRow.valor)
      earningRow.irrf = NumberUtil.usFormat(earningRow.irrf)

      earningRow.date_com = earningRow.date_com || earningRow.date
      const date = DateUtil.dateToString(earningRow.date)
      const date_com = DateUtil.dateToString(earningRow.date_com)

      if (date && date_com) {
        earningRow.date = date
        earningRow.date_com = date_com
      } else {
        return { error: 'data inválida' }
      }

      if (new Date(date) > today || date < '2000-01-01' || new Date(date_com) > today || date_com < '2000-01-01') {
        return { error: 'data fora do limite' }
      }

      if (EventUtil.earningEvents().indexOf(earningRow.evento) < 0) {
        return { error: 'evento inválido' }
      }

      if (earningRow.valor.toString() == 'NaN' || earningRow.irrf.toString() == 'NaN') {
        return { error: 'número inválido' }
      }

      if (Object.keys(StaticParams.currencySymbols).indexOf(earningRow.moeda) < 0) {
        return { error: 'moeda inválida' }
      }

      return earningRow
    } else {
      return {}
    }
  }

  calcIrrfByValorLiquido() {
    const earnings = this.isValidEarningsObject(this.spreadsheet.nativeElement.jexcel.getJson())
    let ativos = FilterUtil.distincValuesOnObjectList(earnings.valid, 'ativo') || []

    const modalRef = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions);
    modalRef.componentInstance.modalProps = {
      type: 'confirm',
      okText: 'Sim',
      cancelText: 'Não',
      message: `
      Você está prestes a calcular o valor bruto e IRRF baseados no valor líquido preenchido.

      <strong>- BDR</strong>
      IRRF 30% (do bruto), 3% (Taxa B3), 0,38% (IOF)

      <strong>- STOCK, REIT, ETF_US</strong>
      IRRF 30% (do bruto)

      <strong>- JSCP (Juros Sobre Capital Próprio)</strong>
      IRRF 15% (do bruto)

      Os dados em "Adicionar Proventos" serão alterados.

      Deseja continuar?
      `,
    }
    modalRef.result.then((result) => {
      if (result.type) {
        this._stockService.getStockInfo(ativos).subscribe({
          next: async (stockInfo) => {
            this.calcIrrf(earnings.valid, stockInfo)
          }
        })
      }
    })
  }

  calcIrrf(objeto, stockinfo) {
    if (!objeto.length) {
      return objeto
    }

    for (let i in objeto) {
      if (objeto[i].irrf) {
        continue
      }
      try {
        let info = stockinfo[objeto[i].ativo].info
        if (info.classe == "BDR") {
          objeto[i].valor = NumberUtil.trunc(objeto[i].valor / (1 - 0.323580), 2)
          objeto[i].irrf = NumberUtil.trunc(objeto[i].valor * 0.3, 2)
        }
        else if ('STOCK,REIT,ETF_US'.split(',').indexOf(info.classe) >= 0) {
          objeto[i].valor = NumberUtil.trunc(objeto[i].valor / (1 - 0.3), 2)
          objeto[i].irrf = NumberUtil.trunc(objeto[i].valor * 0.3, 2)
        }
        else if (objeto[i].evento == "JRS CAP PROPRIO" || objeto[i].evento == "JSCP") {
          objeto[i].valor = NumberUtil.trunc(objeto[i].valor / (1 - 0.15), 2)
          objeto[i].irrf = NumberUtil.trunc(objeto[i].valor * 0.15, 2)
        }
      } catch (e) {
        // this._toastr.warning(`Linha [${parseInt(i) + 1}]: ${objeto[i].ativo} não encontrado no banco de dados`);
      }

    }

    let modalProventos = this._modalService.open(ModalEarningsComponent, modalEarningsOptions);
    modalProventos.componentInstance.data = objeto;
    this.activeModal.close(1);
  }


}
