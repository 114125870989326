import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import DateUtil from '../util/date-util';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class StockService extends BaseApiService {

  constructor(protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService) {
    super(baseApi, router, toastr);
  }

  private getPayloadData(marketCodes: any, return_price: boolean) {
    let start_date = new Date();
    start_date.setMonth(start_date.getMonth() - 1);

    if (!return_price) {
      return {
        targets: marketCodes.toString(),
        start_date: DateUtil.dateToString(start_date),
        end_date: DateUtil.dateToString(new Date()),
        result: 'info,events,fundamentos'
      }
    }

    return {
      targets: marketCodes.toString(),
      start_date: DateUtil.dateToString(start_date),
      end_date: DateUtil.dateToString(new Date())
    }
  }

  getStockData(marketCodes: any, return_price: boolean = true) {
    return this.post(this.urlBaseApi + `/stock/wid/${this.walletId}`, this.getPayloadData(marketCodes, return_price));
  }

  getStockCustomData(payload: any) {
    return this.post(this.urlBaseApi + `/stock/custom`, payload);
  }

  getStockInfo(marketCodes: any) {
    return this.post(this.urlBaseApi + `/stock/info`, this.getPayloadData(marketCodes, false));
  }

  getIndividualStock(asset: string, historic_months: number = null) {
    let queryParam = `asset=${asset}`
    if (historic_months) {
      queryParam = queryParam + `&historic_months=${historic_months}`
    }

    return this.get(this.urlBaseApi + `/stock/individual?${queryParam}`);
  }

  getTickers() {
    return this.get(this.urlBaseApi + `/stock/tickers`);
  }
}
