import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ViewOptionsService {
  private isValueVisible: boolean;
  public behaviorVisible: BehaviorSubject<boolean>;

  private isDarkMode: boolean;
  public behaviorDarkMode: BehaviorSubject<boolean>;

  private isToShowDetails: boolean;
  public behaviorShowDetails: BehaviorSubject<boolean>;

  private isToShowCurrency: boolean;
  public behaviorShowCurrency: BehaviorSubject<boolean>;

  private isToGroupFxAsset: boolean;
  public behaviorGroupFxAsset: BehaviorSubject<boolean>;

  constructor(private _userService: UserService) {
    this.isValueVisible = JSON.parse(localStorage.getItem('visibleValues') || 'true')
    this.isValueVisible = !this.isValueVisible ? false : true
    this.behaviorVisible = new BehaviorSubject(this.isValueVisible)

    let usuario = JSON.parse(localStorage.getItem('usuario'))
    
    this.isDarkMode = usuario && usuario.in_dark_mode ? usuario.in_dark_mode : false
    this.behaviorDarkMode = new BehaviorSubject(this.isDarkMode)

    this.isToShowDetails = usuario && usuario.in_show_details ? usuario.in_show_details : false
    this.behaviorShowDetails = new BehaviorSubject(this.isToShowDetails)

    this.isToShowCurrency = usuario && usuario.in_show_currency ? usuario.in_show_currency : false
    this.behaviorShowCurrency = new BehaviorSubject(this.isToShowCurrency)

    this.isToGroupFxAsset = usuario && usuario.in_group_fx_asset ? usuario.in_group_fx_asset : false
    this.behaviorGroupFxAsset = new BehaviorSubject(this.isToGroupFxAsset)
  }

  changeValueVisible() {
    this.isValueVisible = !this.isValueVisible;
    localStorage.setItem('visibleValues', JSON.stringify(this.isValueVisible))
    this.behaviorVisible.next(this.isValueVisible);
  }

  changeDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    this.behaviorDarkMode.next(this.isDarkMode);
    let usuario = JSON.parse(localStorage.getItem('usuario')) || {}
    usuario.in_dark_mode = this.isDarkMode
    localStorage.setItem('usuario', JSON.stringify(usuario))
    this._userService.updateTheme(this.isDarkMode).subscribe(result => {})
  }

  changeShowDetails() {
    this.isToShowDetails = !this.isToShowDetails;
    this.behaviorShowDetails.next(this.isToShowDetails);
    let usuario = JSON.parse(localStorage.getItem('usuario')) || {}
    usuario.in_show_details = this.isToShowDetails
    localStorage.setItem('usuario', JSON.stringify(usuario))
    this._userService.updateShowDetails(this.isToShowDetails).subscribe(result => {})
  }

  changeShowCurency() {
    this.isToShowCurrency = !this.isToShowCurrency;
    this.behaviorShowCurrency.next(this.isToShowCurrency);
    let usuario = JSON.parse(localStorage.getItem('usuario')) || {}
    usuario.in_show_currency = this.isToShowCurrency
    localStorage.setItem('usuario', JSON.stringify(usuario))
    this._userService.updateShowCurrency(this.isToShowCurrency).subscribe(result => {})
  }

  changeGroupFxAsset() {
    this.isToGroupFxAsset = !this.isToGroupFxAsset;
    this.behaviorGroupFxAsset.next(this.isToGroupFxAsset);
    let usuario = JSON.parse(localStorage.getItem('usuario')) || {}
    usuario.in_group_fx_asset = this.isToGroupFxAsset
    localStorage.setItem('usuario', JSON.stringify(usuario))
    this._userService.updateGroupFxAsset(this.isToGroupFxAsset).subscribe(result => {})
  }
}
