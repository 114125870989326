import { Injectable } from '@angular/core';
import StaticParams from 'src/app/util/static-params';

@Injectable({
  providedIn: 'root'
})
export class DynamicStyleService {

  tablePadding(value: string) {
    if (!value && value != '0') {
      value = '1'
    }
    return `pl-${value} pr-${value} pt-${value} pb-${value} text-center`
  }

  formatNumbersClass(value: number, negativeColor: string, positiveColor: string) {
    value = value || 0
    negativeColor = negativeColor || 'red'
    positiveColor = positiveColor || 'dark'

    if (value == 0) {
      return `${this.tablePadding('1')} color-gray`
    }
    else if (value < 0) {
      return `${this.tablePadding('1')} text-${negativeColor}`
    }
    else if (value > 0) {
      return `${this.tablePadding('1')} text-${positiveColor}`
    }
    else {
      return `${this.tablePadding('1')} color-dark`
    }
  }

  formatStockClass(value: string) {
    value = value || ''
    value = value.toUpperCase()
    let classStyle = StaticParams.tableTextClassStyleColor()

    if (value.indexOf('-12-') >= 0) {
      value = '-12-'
    }

    if (value.indexOf('STOCK') >= 0) {
      value = 'STOCK'
    }
    else if (value.indexOf('REIT') >= 0) {
      value = 'REIT'
    }
    else if (value.indexOf('ETF_') >= 0) {
      value = 'ETF_'
    } else if (value.indexOf('.FUT') >= 0) {
      value = '.FUT'
    }

    if (classStyle[value]) {
      return `${classStyle[value]} font-weight-bold ${this.tablePadding('1')}`
    } else {
      return `font-weight-bold ${this.tablePadding('1')}`
    }
  }

  stylePriceColor(value: number, negativeColor: string, positiveColor: string) {
    value = value || 0
    negativeColor = negativeColor || 'red'
    positiveColor = positiveColor || 'blue'

    if (value < 0) {
      return { "color": negativeColor }
    } else if (value > 0) {
      return { "color": positiveColor }
    }
  }

  decimalFormat(value: any, minDecimals) {
    value = value || 0
    let num = Math.abs(parseFloat(value))

    if (num < 1 || minDecimals == 8) {
      return '1.2-8'
    } else if (minDecimals == 4) {
      return '1.2-4'
    } else {
      return '1.2-2'
    }
  }

  setMinWindowHeigth() {
    const windowheigth = window.innerHeight
    let styles = {
      'min-height': (windowheigth - 310) + 'px',
      'padding': '2px',
    };
    return styles;
  }

}
