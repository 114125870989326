import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { RowSheetEarnings } from '../model/row-sheet-earnings';
import DateUtil from 'src/app/util/date-util';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class EarningsService extends BaseApiService {

  constructor(
    protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService
  ) {
    super(baseApi, router, toastr);
  }

  addEarnings(data: Array<RowSheetEarnings>, calc: boolean = true): Observable<any> {
    return this.post(this.urlBaseApi + `/proventos/wid/${this.walletId}/calc/${calc}`, data);
  }

  getEarnings(filters = null, page = 1, qty_per_page = 10): Observable<any> {
    let url = `/proventos/wid/${this.walletId}`
    let filtersUrl = "?pagina=" + page + "&qtd_pagina=" + qty_per_page

    if (filters) {
      if (filters.asset_class != null) {
        filtersUrl += "&classe=" + filters.asset_class
      }

      if (filters.asset != null) {
        filtersUrl += ("&ativo=" + encodeURI(filters.asset)).replaceAll("+", "%2B")
      }

      if (filters.start_date != null && filters.start_date.year) {
        filtersUrl += "&date_ini=" + DateUtil.ngbDatePickerToString(filters.start_date)
      }

      if (filters.end_date != null && filters.end_date.year) {
        filtersUrl += "&date_fim=" + DateUtil.ngbDatePickerToString(filters.end_date)
      }

      if (filters.broker != null) {
        filtersUrl += "&corretora=" + filters.broker
      }

      if (filters.event != null) {
        filtersUrl += "&evento=" + filters.event
      }
    }

    return this.get(this.urlBaseApi + url + filtersUrl);
  }

  getAllEarnings(): Observable<any> {
    return this.get(this.urlBaseApi + `/proventos/wid/${this.walletId}`);
  }

  deleteSelected(earnings): Observable<any> {
    return this.delete(this.urlBaseApi + `/proventos/excluir/wid/${this.walletId}`, earnings);
  }

  deleteAll(): Observable<any> {
    return this.delete(this.urlBaseApi + `/proventos/excluir/todos/wid/${this.walletId}`, false);
  }

  editList(earnings): Observable<any> {
    return this.put(this.urlBaseApi + `/proventos/atualizar/wid/${this.walletId}`, earnings);
  }

}
