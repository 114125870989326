import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalCentralNotificacoesComponent, modalCentralNotificacoesOptions } from '../modal-central-notificacoes/modal-central-notificacoes.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public notifications = []
  public unreadNotifications = []
  @Input() autoOpenModal: boolean;
  private _autoOpenModalCounter = 0;

  constructor(
    private _modalService: NgbModal,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this._notificationService.behaviorNotifications.subscribe({
      next: (result) => {
        this.notifications = result?.notifications || []
        this.unreadNotifications = result?.unreadNotifications || []
        
        if (this.autoOpenModal) {
          if (this._autoOpenModalCounter > 0) {
            this.openModalCentralNotifications('email')
            this.autoOpenModal = false
          }

          this._autoOpenModalCounter += 1
        }
      }
    })
  }

  async openModalCentralNotifications(customTab: string = null) {
    const modalRef = this._modalService.open(ModalCentralNotificacoesComponent, modalCentralNotificacoesOptions)
    modalRef.componentInstance.modalProps = {
      data: this.notifications,
      tab: customTab
    }

    modalRef.result.then(async (closeOption) => {
      this._notificationService.calcUnreadAndNotifications()
      
      if (closeOption == 'changeOptions') {
        this.openModalCentralNotifications()
      }
    });
  }

}

