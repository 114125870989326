export default class StorageUtil {

    static getActiveWalletName() {
        let activeWalletid = localStorage.getItem('walletId')
        let user = JSON.parse(localStorage.getItem('usuario'))
        let wallets = user.carteiras
        if (wallets && wallets.length) {
            for (let i in wallets) {
                if (wallets[i].id == activeWalletid) {
                    return wallets[i]
                }
            }
        }
        return {}
    }

    static isValidInvestidorPlan() {
        let activeWalletid = localStorage.getItem('walletId')
        let user = JSON.parse(localStorage.getItem('usuario'))
        let wallets = user.carteiras
        if (wallets && wallets.length) {
            for (let i in wallets) {
                if (wallets[i].id == activeWalletid) {
                    let iNum = parseInt(i) + 1
                    let investidoresLimit = 1
                    if (user.alpha && user.alpha.status == true) {
                        investidoresLimit = parseInt(user.alpha.cd_plano.split('_')[1]) || 1
                    }
                    if ((iNum == 1) || (iNum <= investidoresLimit)) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        }
        return false
    }

    static isValidInvestidorLimit() {
        let user = JSON.parse(localStorage.getItem('usuario'))
        let wallets = user.carteiras
        if (wallets && wallets.length) {
            let investidoresLimit = 1
            if (user.alpha && user.alpha.status == true) {
                investidoresLimit = parseInt(user.alpha.cd_plano.split('_')[1]) || 1 // (comentado para testes do multi investidor)
            }
            if (wallets.length < investidoresLimit) {
                return true
            }
        }
        return false
    }
}

