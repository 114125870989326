export enum CategoriaEnum {
    brasil = "Ativos Brasil (Ação, ETF, BDR, FII, FI Agro, FIDC, ...)",
    rfixa = "CDB/LCI/LCA/LC/RDB",
    td = "Tesouro Direto",
    cvm = "Fundo de Investimento",
    internacionais = "Internacionais",
    criptomoeda = "Criptomoedas",
    deb = "Debentures",
    opcoes = "Opções",
    mercado_futuro = "Mercado Futuro",
    moeda = "Moedas",
    ouro = "Ouro Físico / Papel",
    outros = "Outros Ativos"
}
