import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as jspreadsheet from 'jspreadsheet-ce';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OperationsService } from 'src/app/services/operations.service';
import { SummaryService } from 'src/app/services/summary.service';
import DateUtil from 'src/app/util/date-util';
import EventUtil from 'src/app/util/event.util';
import FilterUtil from 'src/app/util/filter-util';
import NumberUtil from 'src/app/util/number-util';
import StaticParams from 'src/app/util/static-params';
import StorageUtil from 'src/app/util/storage-util';

export const modalOperationsOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-operations' };

@Component({
  selector: 'app-modal-operations',
  templateUrl: './modal-operations.component.html',
  styleUrls: ['./modal-operations.component.scss']
})
export class ModalOperationsComponent {

  @ViewChild('spreadsheet') spreadsheet: ElementRef;

  public title = ''
  public closeText = ''
  public data: any = [{}];
  public table: any;
  public loading = false

  public slicedData = []
  public pagesDataLen = 0
  public saveDataPage = 0
  public isEditingRows = 0
  public isLoadingBackup: boolean = false

  constructor(
    private _serviceOperations: OperationsService,
    private _toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private _summaryService: SummaryService,
    private _router: Router,
    private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.title = this.title ? this.title : this.data[0]?.id ? "Editar Operações" : "Adicionar Operações"
    this.closeText = this.closeText || "Fechar"

    this.isEditingRows = this.data[0]?.id ? 10 : 0
    this.data = this.removeCalculatedPropetys(this.data)

    this.slicedData = this.sliceIntoChunks(this.data, 5000)
    this.pagesDataLen = this.slicedData.length
  }

  sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  removeCalculatedPropetys(operacoes) {
    let result = []
    for (let i in operacoes) {
      let { ativo, corretora, date, evento, irrf, moeda, qtd, preco, taxas, observacao, id } = operacoes[i]
      if (this.isEditingRows) {
        result.push({ ativo, corretora, date, evento, irrf, moeda, qtd, preco, taxas, observacao, id })
      } else {
        result.push({ ativo, corretora, date, evento, irrf, moeda, qtd, preco, taxas, observacao })
      }
    }
    return FilterUtil.lodash().sortBy(result, ['ativo', 'corretora', 'date'])
  }

  ngAfterViewInit() {
    this.table = jspreadsheet(this.spreadsheet.nativeElement, {
      data: [],
      columns: this.getBrowserModel(),
      minDimensions: [
        this.data[0]?.length ? this.data[0]?.length : 10,
        this.isEditingRows ? this.data?.length : 15,
      ],
      allowManualInsertColumn: false,
      allowDeleteColumn: false,
      columnResize: true,
      allowManualInsertRow: this.isEditingRows ? false : true,
      allowDeleteRow: this.isEditingRows ? false : true,
    });
    this.table.setData(this.slicedData[this.saveDataPage])
  }

  getBrowserModel() {
    let result = []
    result = [
      {
        title: '*Ativo',
        width: 110,
        name: 'ativo',
      },
      {
        title: '*Data',
        width: 80,
        name: 'date',
        // type: "calendar",
        type: 'text',
      },
      {
        title: "*Evento",
        name: 'evento',
        type: "dropdown",
        width: 90,
        source: EventUtil.operationEvents(),
        autocomplete: true,
      },
      {
        title: 'Qtd.',
        width: 80,
        name: 'qtd',
        decimal: ',',
      },
      {
        title: 'Preço',
        width: 80,
        name: 'preco',
        decimal: ',',
      },
      {
        title: 'Taxas',
        width: 80,
        name: 'taxas',
        decimal: ',',
      },
      {
        title: '*Corretora',
        width: 100,
        name: 'corretora'
      },
      {
        title: 'IRRF',
        width: 80,
        name: 'irrf',
        decimal: ',',
      },
      {
        title: "Moeda",
        name: 'moeda',
        type: "dropdown",
        width: 80,
        source: Object.keys(StaticParams.currencySymbols),
        autocomplete: true,
      },
      {
        title: "Observação",
        name: 'observacao',
        type: "text",
        width: 100,
      },
    ]

    let browserName = StaticParams.fnBrowserDetect()
    if ('firefox,safari'.split(',').indexOf(browserName) >= 0) {
      result[1].type = "calendar"
      result[8].width = 90
      result[9].width = 90
    }
    else if ('chrome,opera'.split(',').indexOf(browserName) >= 0) {
      result[1].mask = 'dd/mm/yyyy'
    }

    if (this.isEditingRows) {
      result.push({
        title: "Id",
        width: 100,
        name: 'id',
        type: "hidden",
      })
    }

    return result
  }

  async save() {
    if (!StorageUtil.isValidInvestidorPlan()) {
      this._toastr.warning('Limite de investidores do plano atingido! Verifique seu plano em "Minha Conta"');
      this._router.navigate(['/alpha']);
      this.activeModal.close()
      return
    }

    this.loading = true
    this.spinnerService.show()
    const operations = this.isValidOperationsObject(this.spreadsheet.nativeElement.jexcel.getJson())

    if (operations.error.length == 0 && operations.valid.length > 0 && !this.isEditingRows) {
      this._toastr.info('Processando. Aguarde!')
      let calculate_historic_and_portfolio: boolean = true
      if (this.isLoadingBackup) {
        calculate_historic_and_portfolio = false
      }
      this._serviceOperations.add(operations.valid, calculate_historic_and_portfolio).subscribe({
        next: (x) => {
          this._toastr.success('Operações adicionadas')
          if (!this.isLoadingBackup) {
            this._summaryService.updateSummaryBehavior()
          }

          this.loading = false
          this.spinnerService.hide()
          this.saveDataPage++
          if (this.saveDataPage == this.pagesDataLen) {
            this.activeModal.close(1)
          } else {
            this.table.setData(this.slicedData[this.saveDataPage])
          }
        },
        error: (err) => {
          this.loading = false
          this.spinnerService.hide()
        }
      })
    }
    else if (operations.error.length == 0 && operations.valid.length > 0 && this.isEditingRows) {
      this._toastr.info('Processando. Aguarde!')
      this._serviceOperations.editList(operations.valid).subscribe({
        next: (x) => {
          this._toastr.success('Operações editadas')
          this._summaryService.updateSummaryBehavior()
          this.loading = false
          this.spinnerService.hide()
          this.saveDataPage++
          if (this.saveDataPage == this.pagesDataLen) {
            this.activeModal.close(1)
          } else {
            this.table.setData(this.slicedData[this.saveDataPage])
          }
        },
        error: (err) => {
          this.loading = false
          this.spinnerService.hide()
        }
      })
    }
    else if (operations.valid.length == 0 && operations.error.length == 0) {
      this._toastr.error('Preencha os campos obrigatórios!');
      this.loading = false
      this.spinnerService.hide()
    } else {
      this.loading = false
      this.spinnerService.hide()
    }

  }

  isValidOperationsObject(operationsObject) {
    const result = {
      valid: [],
      error: [],
    }
    for (let i in operationsObject) {
      let reqFields = operationsObject[i].ativo && operationsObject[i].date && operationsObject[i].corretora
      let row = this.isValidOperationRow(operationsObject[i])
      if (!row.error && reqFields) {
        result.valid.push(row)
      } else if (row.error && reqFields) {
        result.error.push(operationsObject[i])
        this._toastr.error(`Erro na Linha [${parseInt(i) + 1}]: ${row.error}`);
      }
    }
    return result
  }

  isValidOperationRow(operationRow) {
    let reqFields = operationRow.ativo && operationRow.date && operationRow.corretora
    let today = new Date();
    today.setMonth(today.getMonth() + 3);

    if (reqFields) {
      if (operationRow.preco == 'FATO.REL.' || operationRow.qtd == 'FATO.REL.') {
        return { error: 'verificar fato relevante' }
      }
      if (/[\x00-\x08\x0E-\x1F]/.test(operationRow.irrf)) {
        operationRow.irrf = 0
      }
      operationRow.ativo = operationRow.ativo.trim().toUpperCase().split('  ').join(' ')
      operationRow.corretora = StaticParams.verificaCorretora(operationRow.corretora)
      operationRow.moeda = operationRow.moeda || 'BRL';
      operationRow.qtd = NumberUtil.usFormat(operationRow.qtd)
      operationRow.preco = NumberUtil.usFormat(operationRow.preco)
      operationRow.taxas = NumberUtil.usFormat(operationRow.taxas)
      operationRow.irrf = NumberUtil.usFormat(operationRow.irrf)

      if (operationRow.ativo.indexOf("EX.OP") >= 0) {
        return { error: 'Informe o cod do ativo objeto na EX.OP' }
      }

      const date = DateUtil.dateToString(operationRow.date)
      if (date) {
        operationRow.date = date
      } else {
        return { error: 'data inválida' }
      }

      if (new Date(date) > today || date < '2000-01-01') {
        return { error: 'data fora do limite' }
      }

      if (!operationRow.evento) {
        if (operationRow.qtd || operationRow.preco < 0) {
          operationRow.evento = "V"
        } else {
          operationRow.evento = "C"
        }
      }
      if (EventUtil.operationEvents().indexOf(operationRow.evento) < 0) {
        return { error: 'evento inválido' }
      }
      if (
        operationRow.qtd.toString() == 'NaN' ||
        operationRow.preco.toString() == 'NaN' ||
        operationRow.taxas.toString() == 'NaN' ||
        operationRow.irrf.toString() == 'NaN'
      ) {
        return { error: 'número inválido' }
      }
      if (Object.keys(StaticParams.currencySymbols).indexOf(operationRow.moeda) < 0) {
        return { error: 'moeda inválida' }
      }

      return operationRow
    } else {
      return {}
    }
  }

}
