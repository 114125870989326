import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  public cache: any = {}

  constructor() { }

  getCache(key: string) {
    if (!key) { return this.cache }
    return this.cache[key] ? JSON.parse(JSON.stringify(this.cache[key])) : null
  }

  setCache(data: any) {
    const keys = Object.keys(data)
    keys.forEach(el => {
      this.cache[el] = JSON.parse(JSON.stringify(data[el]))
    })
  }

  removeCache(key: string) {
    delete this.cache[key]
  }

  clearCache() {
    this.cache = {}
  }

  clearCacheOnOperationsChange() {
    this.removeCache('indexObject')
    this.removeCache('dirpfLocal')
    this.removeCache('darfObject')
    this.removeCache('historyData')
    this.removeCache('historyObject')
  }

  clearCacheOnInvestidorChange() {
    this.clearCacheOnOperationsChange()
    localStorage.removeItem('walletFilter')
    localStorage.removeItem('historyFilter')
    localStorage.removeItem('stockData')
  }

  async getCacheWallet() {
    let cachedWallet = null
    for (let i = 0; i <= 10; i++) {
      cachedWallet = this.getCache('walletObject')
      if (cachedWallet) {
        return cachedWallet
      }
      await new Promise(resolve => setTimeout(resolve, 2000))
    }
    return cachedWallet
  }
}
