import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionEnum } from '../enums/permission-enum';
import AssetUtil from '../util/asset.util';
import { AuthService } from './auth.service';
import { BaseApiService } from './base-api.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class AlocacaoService extends BaseApiService {

  constructor(
    protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService,
    private _authService: AuthService,
    private _cacheService: CacheService,
  ) {
    super(baseApi, router, toastr);
  }

  getAlocacaoWallet(): Observable<any> {
    return this.get(this.urlBaseApi + `/multicarteiras/wid/${this.walletId}`);
  }

  createAlocacaoWallet(alocacaoWallet): Observable<any> {
    const payload = {
      id: alocacaoWallet.id || null,
      numero_carteira: alocacaoWallet.numero_carteira,
      nome_carteira: alocacaoWallet.nome_carteira,
      peso_carteira: alocacaoWallet.peso_carteira,
      // nome_carteira_reduzido: alocacaoWallet.nome_carteira_reduzido || null,
      // perfil_carteira: alocacaoWallet.perfil_carteira || null,
      ativos: alocacaoWallet.ativos || null,
    }
    return this.post(this.urlBaseApi + `/multicarteiras/wid/${this.walletId}`, payload);
  }

  updateAlocacaoWallet(alocacaoWallet): Observable<any> {
    const payload = {
      id: alocacaoWallet.id || null,
      numero_carteira: alocacaoWallet.numero_carteira,
      nome_carteira: alocacaoWallet.nome_carteira,
      peso_carteira: alocacaoWallet.peso_carteira,
      // nome_carteira_reduzido: alocacaoWallet.nome_carteira_reduzido || null,
      // perfil_carteira: alocacaoWallet.perfil_carteira || null,
      ativos: alocacaoWallet.ativos || null,
    }
    return this.put(this.urlBaseApi + `/multicarteiras/wid/${this.walletId}`, payload);
  }

  deleteAlocacaoWallet(alocacaoWallet): Observable<any> {
    const payload: any = { id: alocacaoWallet.id }
    return this.delete(this.urlBaseApi + `/multicarteiras/wid/${this.walletId}`, payload);
  }

  setAlocacaoWalletCache(alocacaoWallets) {
    let newObject = JSON.parse(JSON.stringify(alocacaoWallets)) || {}

    let wallets = {}
    let ativos_obj = {}
    let ativos = []
    let carteiras = []

    for (let i in newObject) {
      for (let j in newObject[i].ativos) {
        if (ativos_obj[newObject[i].ativos[j].ativo]) {
          ativos_obj[newObject[i].ativos[j].ativo].push(newObject[i].nome_carteira)
        } else {
          ativos_obj[newObject[i].ativos[j].ativo] = [newObject[i].nome_carteira]
        }
        newObject[i].ativos[j].carteira = newObject[i].nome_carteira
      }
      carteiras.push(newObject[i].nome_carteira)
      ativos = ativos.concat(newObject[i].ativos)
    }

    for (let i in newObject) {
      wallets[newObject[i].id] = newObject[i]
    }

    let result = { wallets, carteiras, ativos, ativos_obj }
    this._cacheService.setCache({ 'alocacaoCache': result })
  }

  getAlocacaoCache() {
    if (!this._authService.hasUserPermission(PermissionEnum.ALOCACAO)) { return {} }
    let localAlocacaoCache = this._cacheService.getCache('alocacaoCache') || {}
    if (localAlocacaoCache.wallets && localAlocacaoCache.carteiras && localAlocacaoCache.ativos) {
      return localAlocacaoCache
    } else {
      return null
    }
  }

  getAtivosFiltrados(parametroFiltro) {
    let alocacao = this._cacheService.getCache('alocacaoCache')
    let ativosFiltrados = []
    alocacao.ativos.forEach(o => {
      ativosFiltrados = parametroFiltro.indexOf(o.carteira) >= 0 ? ativosFiltrados.concat(o.ativo) : ativosFiltrados
    });
    return ativosFiltrados
  }

  addCarteiraOnObject(object) {
    let alocacaoCache = this.getAlocacaoCache()
    object.forEach(row => { row.carteira = alocacaoCache.ativos_obj[row.ativo] || null })
    return object
  }

  addParametroCarteiraNoObjeto(objeto, objectName) {
    const result = {}
    if (!this._authService.hasUserPermission(PermissionEnum.ALOCACAO)) {
      result[objectName] = objeto
      this._cacheService.setCache(result)
      return objeto
    }

    let alocacaoCache = this.getAlocacaoCache()
    objeto.forEach(row => {
      let alocationAsset = row.ativo
      if (alocationAsset.endsWith("_E")) {
        alocationAsset = AssetUtil.getOriginalAssetFromForeignBuy(alocationAsset)
      }

      row.carteira = alocacaoCache?.ativos_obj[alocationAsset] || null
    })
    result[objectName] = objeto
    this._cacheService.setCache(result)
    return objeto
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
