import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective } from 'ngx-mask';
import { FooterComponent } from './footer/footer.component';
import { HeaderInfoComponent } from './header-info/header-info.component';
import { ModalOperationsComponent } from './modal-operations/modal-operations.component';
import { ModalProfileComponent } from './modal-profile/modal-profile.component';
import { ModalSettingsComponent } from './modal-settings/modal-settings.component';
import { NavbarUserComponent } from './navbar-user/navbar-user.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ModalConfirmationDialogComponent } from './modal-confirmation-dialog/modal-confirmation-dialog.component';
import { ModalEarningsComponent } from './modal-earnings/modal-earnings.component';
import { ModalTermosUsoComponent } from './modal-termos-uso/modal-termos-uso.component';
import { ModalTableComponent } from './modal-table/modal-table.component';
import { ModalMultiInvestidorComponent } from './modal-multi-investidor/modal-multi-investidor.component';
import { ModalAlocacaoComponent } from './modal-alocacao/modal-alocacao.component';
import { TourNgxBootstrapModule } from 'ngx-ui-tour-ngx-bootstrap';
import { ModalFundamentosComponent } from './modal-fundamentos/modal-fundamentos.component';
import { AtivoInfoComponent } from './modal-fundamentos/ativo-info/ativo-info.component';
import { AtivoGraficoComponent } from './modal-fundamentos/ativo-grafico/ativo-grafico.component';
import { AtivoNoticiasComponent } from './modal-fundamentos/ativo-noticias/ativo-noticias.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ModalCentralNotificacoesComponent } from './modal-central-notificacoes/modal-central-notificacoes.component';
import { ModalPixComponent } from './modal-pix/modal-pix.component';
import { ModalSimpleOperationComponent } from './modal-simple-operation/modal-simple-operation.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalSimpleAddOutrosComponent } from './modal-simple-add-outros/modal-simple-add-outros.component';
import { CurrencyMaskModule, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "center",
    allowNegative: true,
    decimal: ",",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: "."
};

@NgModule({
  imports: [
    CurrencyMaskModule,
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    TourNgxBootstrapModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskDirective
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ModalOperationsComponent,
    ModalProfileComponent,
    ModalSettingsComponent,
    HeaderInfoComponent,
    NavbarUserComponent,
    ModalConfirmationDialogComponent,
    ModalEarningsComponent,
    ModalTermosUsoComponent,
    ModalTableComponent,
    ModalMultiInvestidorComponent,
    ModalAlocacaoComponent,
    ModalFundamentosComponent,
    AtivoInfoComponent,
    AtivoGraficoComponent,
    AtivoNoticiasComponent,
    NotificationsComponent,
    ModalCentralNotificacoesComponent,
    ModalPixComponent,
    ModalSimpleOperationComponent,
    ModalSimpleAddOutrosComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ModalOperationsComponent,
    HeaderInfoComponent,
  ],
  providers: [
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
})
export class ComponentsModule { }
