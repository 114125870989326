import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { ViewOptionsService } from 'src/app/services/view-options.service';

@Component({
  selector: 'app-modal-termos-uso',
  templateUrl: './modal-termos-uso.component.html',
  styleUrls: ['./modal-termos-uso.component.scss']
})
export class ModalTermosUsoComponent implements OnInit {

  public isDarkMode = false
  public aceiteTermosUso: boolean;
  public btnLoading: boolean = false;
  public checkAceitarTermosUso: boolean = false;

  constructor(
    private _authService: AuthService,
    public activeModal: NgbActiveModal,
    private _toastr: ToastrService,
    private _userService: UserService,
    private _viewOptionsService: ViewOptionsService,
  ) { }

  ngOnInit(): void {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);

    this.aceiteTermosUso = this._authService.getDecodedJwt().aceite_termos_uso;
    let x: any = document.getElementById('text-terms-use');
    x.setAttribute("tabindex", 1);
    x.focus()
  }

  aceitarTermosUso(): void {
    this.btnLoading = true;

    if (!this.checkAceitarTermosUso) {
      this._toastr.warning("Leia todo documento e confirme o aceite dos termos no final.")
      this.btnLoading = false;
    } else {
      this._userService.updateTermsOfUse().subscribe({
        next: (result) => {
          this._userService.getUser().subscribe({
            next: (user) => {
              this._authService.setSession(user);
              this._toastr.success(result.message);
              this.activeModal.close(1);
            },
            error: (err) => {
              this.btnLoading = false;
            }
          })
        },
        error: (err) => {
          this.btnLoading = false;
        }
      })
    }
  }

  logout() {
    this._authService.logout()
  }

}
