export default class EventUtil {

    static earningEvents() {
        return [
            "DIVIDENDO",
            "JSCP",
            "RENDIMENTO",
            "BONIFICACAO",
            "BTC",
            "V.FRACAO",
            "REEMBOLSO",
            "CRED.NAO.PAGO(JSCP)",
            "AMORTIZACAO"
        ]
    }

    static operationEvents() {
        return [
            "C",
            "V",
            "DT",
            "SUB",
            "V.SUB",
            "BN",
            "IPO",
            "S/I",
            "AT",
            "TC",
            "BAIXA",
            "C.COTA",
            "R.CAP",
            "AM",
            "CISÃO",
            "AJ.POS",
            "EX.OPC",
            "EX.OPV",
            "TERMO",
            "ISENTO",
            "TAXA",
            "SALDO",
            "GRUPAMENTO",
            "DESDOBRAMENTO",
            "V.FRACAO",
            "AJ.PRECO"
        ]
    }

    static operationsEventsWithAlias() {
        return [
            {"event": "C", "show": "COMPRA"},
            {"event": "V", "show": "VENDA"},
            {"event": "DT", "show": "DAY TRADE"},
            {"event": "SUB", "show": "SUBSCRIÇÃO"},
            {"event": "V.SUB", "show": "VENDA DIR. SUBSC."},
            {"event":"BN", "show": "BONIFICAÇÃO"},
            {"event":"IPO", "show": "IPO"},
            {"event":"S/I", "show": "SPLIT/INPLIT"},
            {"event":"AT", "show": "ALT. TICKER"},
            {"event":"TC", "show": "TRANSF. CUST."},
            {"event":"BAIXA", "show": "BAIXA"},
            {"event":"C.COTA", "show": "COME COTAS"},
            {"event":"R.CAP", "show": "REST. CAPITAL"},
            {"event":"AM", "show": "AMORTIZAÇÃO"},
            {"event":"CISÃO", "show": "CISÃO"},
            {"event":"AJ.POS", "show": "AJ. POSIÇÃO"},
            {"event":"EX.OPC", "show": "EX. OPÇÃO"},
            {"event":"EX.OPV", "show": "EX. OPÇÃO VENDIDA"},
            {"event":"TERMO", "show": "OP. A TERMO"},
            {"event":"ISENTO", "show": "ISENTO"},
            {"event":"TAXA", "show": "TAXA"},
            {"event":"SALDO", "show": "SALDO"},
            {"event":"GRUPAMENTO", "show": "GRUPAMENTO"},
            {"event":"DESDOBRAMENTO", "show": "DESDOBRAMENTO"},
            {"event":"V.FRACAO", "show": "V.FRAÇÃO"},
            {"event":"AJ.PRECO", "show": "AJ.PREÇO"}
        ]
    }

    static mapEarningEvents(event: string): string {
        switch (event) {
            case "JRS CAP PROPRIO":
                return "JSCP"
            case "V.SOB(BN)":
                return "V.FRACAO"
            case "REEMBOLSO(DIV)":
            case "REEMBOLSO(JSCP)":
                return "REEMBOLSO"
            default:
                return event
        }
    }

    static mapOperationsEvents(event: string): string {
        switch (event) {
            case "V.SOB":
                return "V.FRACAO"
            case "TX.BTC":
                return "TAXA"
            default:
                return event
        }
    }

}