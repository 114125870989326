import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as jspreadsheet from 'jspreadsheet-ce';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlocacaoService } from 'src/app/services/alocacao.service';
import NumberUtil from 'src/app/util/number-util';

export const modalAlocacaoOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-alocacao' };

@Component({
  selector: 'app-modal-alocacao',
  templateUrl: './modal-alocacao.component.html',
  styleUrls: ['./modal-alocacao.component.scss']
})
export class ModalAlocacaoComponent {

  @ViewChild('spreadsheet') spreadsheet: ElementRef;

  public alocacaoWallet: any = {}
  public data = [{}];
  public table: any;

  constructor(
    private _alocacaoService: AlocacaoService,
    public activeModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.alocacaoWallet.ativos = this.setwalletAtivosPropertys(this.alocacaoWallet.ativos)
  }

  ngAfterViewInit() {
    this.table = jspreadsheet(this.spreadsheet.nativeElement, {
      data: this.alocacaoWallet.ativos,
      columns: [
        {
          title: 'Rank',
          name: 'rank',
          width: 32,
        },
        {
          title: "*Ativo",
          name: 'ativo',
          type: "text",
          width: 120,
        },
        {
          title: 'P.Teto',
          name: 'preco_teto',
          width: 50,
          decimal: ',',
        },
        {
          title: 'Peso (%)',
          name: 'peso_ativo',
          width: 50,
          decimal: ',',
        },
      ],
      minDimensions: [4, 15],
      allowManualInsertColumn: false,
    });
  }

  async save() {
    this.spinnerService.show();
    const alocacaoWalletAtivos = this.spreadsheet.nativeElement.jexcel.getJson()
    let ativosValidos = []
    for (let j in alocacaoWalletAtivos) {
      if (alocacaoWalletAtivos[j].ativo) {
        alocacaoWalletAtivos[j].rank = parseInt(alocacaoWalletAtivos[j].rank) || null
        alocacaoWalletAtivos[j].ativo = alocacaoWalletAtivos[j].ativo.toUpperCase().trim()
        alocacaoWalletAtivos[j].peso_ativo = NumberUtil.percentStringToNumber(alocacaoWalletAtivos[j].peso_ativo)
        alocacaoWalletAtivos[j].preco_teto = NumberUtil.usFormat(alocacaoWalletAtivos[j].preco_teto)
        ativosValidos.push(alocacaoWalletAtivos[j])
      }
    }
    this.alocacaoWallet.peso_carteira = NumberUtil.percentStringToNumber(this.alocacaoWallet.peso_carteira)
    this.alocacaoWallet.ativos = ativosValidos

    this._alocacaoService.updateAlocacaoWallet(this.alocacaoWallet).subscribe({
      next: (res) => {
        this.activeModal.close(res);
        this.spinnerService.hide();
      },
      error: (err) => {
        this.spinnerService.hide();
      }
    })
  }

  setwalletAtivosPropertys(walletAtivos) {
    let result = []
    for (let i in walletAtivos) {
      let row: any = {}
      row.rank = walletAtivos[i].rank
      row.ativo = walletAtivos[i].ativo
      if (walletAtivos[i].peso_ativo || walletAtivos[i].peso_ativo == 0) {
        row.peso_ativo = NumberUtil.roundTo(walletAtivos[i].peso_ativo * 100, 1000) + "%"
      }
      row.preco_teto = NumberUtil.brFormat(walletAtivos[i].preco_teto)
      result.push(row)
    }

    this.alocacaoWallet.peso_carteira = NumberUtil.roundTo(this.alocacaoWallet.peso_carteira * 100, 1000) + "%"
    return result
  }


}
