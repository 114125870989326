import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CacheService } from 'src/app/services/cache.service';
import { DynamicStyleService } from 'src/app/services/dynamic-style.service';
import { SummaryService } from 'src/app/services/summary.service';
import { ViewOptionsService } from 'src/app/services/view-options.service';
import AssetUtil from 'src/app/util/asset.util';
import FilterUtil from 'src/app/util/filter-util';
import StaticParams from 'src/app/util/static-params';

@Component({
  selector: 'app-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss']
})
export class HeaderInfoComponent implements OnInit {
  public isDarkMode = false
  public hideMarquee: boolean = false
  public marqueeIndex: any = []
  public wallet: any = []
  public currencyDefault: string = 'BRL'
  public visibleValues: boolean = true;
  public infoCards = {
    'valueTotal': 0,
    'valueMarket': 0,
    'valueProfit': 0,
    'valueEarnings': 0,
    'avg12mEarnings': 0,
    'operationSt': 0,
    'operationDt': 0,
    'total': 0,
  }
  public lastUpdatedPrices: string = ""

  constructor(
    private _viewOptionsService: ViewOptionsService,
    private _summaryService: SummaryService,
    private _authService: AuthService,
    public dynamicCss: DynamicStyleService,
    private _cacheService: CacheService,
  ) { }

  ngOnInit(): void {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);
    this.hideMarquee = JSON.parse(localStorage.getItem('hideMarquee')) || false
    this.currencyDefault = this._authService.getMoedaBase()
    this._viewOptionsService.behaviorVisible.subscribe(visible => this.visibleValues = visible);
    this._summaryService.updateSummaryBehavior();
    this._subscribeBehaviors()
  }

  private async _subscribeBehaviors() {
    this._summaryService.behaviorWallet.subscribe({
      next: (walletData) => {
        this.calcValueWallet(walletData);
        this.wallet = walletData

        this._summaryService.behaviorSummary.subscribe({
          next: (summaryData) => {
            if (summaryData) {
              this.lastUpdatedPrices = new Date(summaryData.price_update_time).toLocaleString('pt-BR')
              this.calcSummaryCards(summaryData);
            }
          }
        })

      }
    })
  }

  toggleVisible() {
    this._viewOptionsService.changeValueVisible();
  }

  toggleMarquee(tooltip) {
    this.hideMarquee = !this.hideMarquee
    localStorage.setItem('hideMarquee', JSON.stringify(this.hideMarquee));
    tooltip.close()
  }

  calcValueWallet(walletData) {
    this.infoCards.valueTotal = 0
    this.infoCards.valueMarket = 0

    for (let i in walletData) {
      this.infoCards.valueTotal += walletData[i].vlr_investido
      this.infoCards.valueMarket += walletData[i].vlr_mercado
    }

    this.infoCards.valueProfit = this.infoCards.valueMarket - this.infoCards.valueTotal
    this.infoCards.total = this.infoCards.valueProfit + this.infoCards.operationDt + this.infoCards.operationSt + this.infoCards.valueEarnings
  }

  calcSummaryCards(summary) {
    summary = summary?.summary ? summary.summary : summary;
    if (summary) {
      this.infoCards.valueEarnings = summary.earnings.reduce((a, b) => (a + b.total), 0)
      this.infoCards.avg12mEarnings = summary.earnings_average_ttm

      this.infoCards.operationSt = 0
      this.infoCards.operationDt = 0
      this.infoCards.total = 0
      const total_profit = summary.profits

      for (let i in total_profit) {
        if (total_profit[i].daytrade) {
          this.infoCards.operationDt += total_profit[i].profit
        } else {
          this.infoCards.operationSt += total_profit[i].profit
        }
      }
    }

    this.infoCards.total = this.infoCards.valueProfit + this.infoCards.operationDt + this.infoCards.operationSt + this.infoCards.valueEarnings
    this.calcIndexMarquee()
  }

  async calcIndexMarquee() {
    this.marqueeIndex = []
    let index = StaticParams.indexSymbols
    let indexData = JSON.parse(localStorage.getItem('currencyIndexData')) || {}

    this.marqueeIndex = [{ ativo: 'ÍNDICES >' }]

    for (let i in index) {
      if (!indexData[index[i]]) { continue }
      let row: any = {}
      row.ativo = index[i]
      row.price = indexData[index[i]].last.price || 0
      row.changepct = indexData[index[i]].last.changepct || 0
      row.currency = indexData[index[i]].currency || 0

      this.marqueeIndex.push(row)
    }

    this.calcAltasBaixas()
  }

  calcAltasBaixas() {
    this.wallet = this.wallet || this._cacheService.getCache('walletObject') || []

    if (!this.wallet.length) {
      return
    }

    let arrayForeignCurrencyAssets = this.wallet.filter(foreignCurrencyAsset => foreignCurrencyAsset.ativo.endsWith("_E"))
    arrayForeignCurrencyAssets.forEach(asset => {
      const subAsset = asset.ativo
      const originalAsset = AssetUtil.getOriginalAssetFromForeignBuy(subAsset)

      let mainAsset = this.wallet.filter(filterAsset => filterAsset.ativo == originalAsset);
      if (mainAsset.length > 0) {
        this.wallet = this.wallet.filter(filterAsset => filterAsset.ativo != subAsset)
      } else {
        asset.ativo = originalAsset
      }
    })

    this.addToMarquee('ALTAS CARTEIRA >', false)
    this.addToMarquee('BAIXAS CARTEIRA >', true)
  }

  private addToMarquee(title: string, order_asc: boolean) {
    this.marqueeIndex.push({ ativo: title })
    if (order_asc) {
      this.wallet = FilterUtil.lodash().orderBy(this.wallet, ['changepct'], ['asc']);
    } else {
      this.wallet = FilterUtil.lodash().orderBy(this.wallet, ['changepct'], ['desc']);
    }
    this.wallet = FilterUtil.lodash().uniqBy(this.wallet, 'market_cod')

    const loopQtd = this.wallet.length > 5 ? 5 : this.wallet.length
    for (let i = 0; i < loopQtd; i++) {
      let row: any = {}
      row.ativo = this.wallet[i].ativo
      row.price = this.wallet[i].price || 0
      row.changepct = this.wallet[i].changepct || 0
      row.currency = this.currencyDefault
      this.marqueeIndex.push(row)
    }
  }

  currencySymbols(currency) {
    return StaticParams.currencySymbols[currency]
  }
}
