import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { DynamicStyleService } from 'src/app/services/dynamic-style.service';
import { EarningsService } from 'src/app/services/earnings.service';
import { OperationsService } from 'src/app/services/operations.service';
import DateUtil from 'src/app/util/date-util';
import { ModalConfirmationDialogComponent, modalConfirmationDialogOptions } from '../modal-confirmation-dialog/modal-confirmation-dialog.component';
import { CacheService } from 'src/app/services/cache.service';

export const modalFundamentosOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-fundamentos' };

@Component({
  selector: 'app-modal-fundamentos',
  templateUrl: './modal-fundamentos.component.html',
  styleUrls: ['./modal-fundamentos.component.scss']
})
export class ModalFundamentosComponent implements OnInit {

  public modalProps: any = {}
  public activeTable = 'info'

  public info: any = {}
  public fundamentos: any = {}
  public portfolio: any = {}
  public historical: any = {}
  public stockinfo: any = {}
  public operacoes: any = null
  public proventos: any = null
  public table: any = []

  public colValues: any = []
  public colKeys: any = []
  public resultDecimals: any = []
  public isUndefinedAtivo: any = false


  constructor(
    public activeModal: NgbActiveModal,
    private _operationsService: OperationsService,
    private _earningsService: EarningsService,
    public dynamicCss: DynamicStyleService,
    private _modalService: NgbModal,
    private _authService: AuthService,
    private _cacheService: CacheService,
  ) { }

  ngOnInit(): void {
    this.portfolio = this.modalProps.portfolio ? this.modalProps.portfolio : {}
    this.stockinfo = this.modalProps.stockinfo ? this.modalProps.stockinfo : {}

    if (!this.stockinfo.last) {
      this.stockinfo.last = {}
      this.stockinfo.last.price = this.stockinfo?.last?.price || this.portfolio?.price || 0
      this.stockinfo.currency = this.stockinfo.currency ? this.stockinfo.currency : this.portfolio?.moeda || 'BRL'
    }

    this.isUndefinedAtivo = this.stockinfo?.last?.price ? false : true

    if (this.isUndefinedAtivo) {
      const modalAlert = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions)
      modalAlert.componentInstance.modalProps = {
        type: 'confirm',
        title: 'ATENÇÃO',
        message: `
        Ativo ${this.portfolio.ativo} não encontrado em nosso banco de dados!<br>
        Verifique a digitação do mesmo ou verifique nosso FAQ sobre ativos e mercados.`,
        okText: 'Fechar',
        cancelText: ''
      }
      this.activeModal.close()
    }

    this.info = this.modalProps.stockinfo && this.modalProps.stockinfo.info ? this.modalProps.stockinfo.info : {}
    this.fundamentos = this.modalProps.stockinfo && this.modalProps.stockinfo.fundamentos ? this.modalProps.stockinfo.fundamentos : {}
  }

  toggleTable(selectedTable) {
    this.activeTable = selectedTable
    if (selectedTable == "proventos") {
      this.getProventosAtivosList()
    }
    if (selectedTable == "operacoes") {
      this.getOperationsAtivoList()
    }
  }

  getProventosAtivosList() {
    let row: any = this.portfolio
    let userSettings = JSON.parse(localStorage.getItem('usuario'))

    this.colKeys = 'ativo,date,evento,valor,corretora,irrf,moeda,ptax|4,vlr_liquido,vlr_bruto,irrf_total'.split(',')
    this.colValues = 'ativo,date,evento,valor,corretora,irrf,moeda,ptax,liquido,bruto,irrf.Tot'.split(',')
    this.table = this.proventos
    this.formatTable(this.table)

    let corretora = userSettings.calc_por_corretora && row.corretora ? row.corretora : null
    if (!this.proventos) {
      let filters = {
        asset: row.ativo,
        broker: corretora,
        start_date: row.date_ini ? DateUtil.dateStringToNgbDatePicker(row.date_ini) : null,
      }

      const fxOriginAsset = this._getSubFxAsset(row)
      this._earningsService.getEarnings(filters, 1, 100000).subscribe({
        next: async (main) => {
          if (fxOriginAsset) {
            filters.asset = fxOriginAsset
            this._earningsService.getEarnings(filters, 1, 100000).subscribe({
              next: async (sub) => {
                this.proventos = main.result.concat(sub.result)
                this.proventos = this.proventos.sort(
                  (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
                );
                this.table = this.proventos
                this.formatTable(this.table)
              },
              error: () => { }
            })
          } else {
            this.proventos = main.result
            this.table = this.proventos
            this.formatTable(this.table)
          }
        },
        error: () => { }
      })
    }
  }

  getOperationsAtivoList() {
    let row: any = this.portfolio
    let userSettings = JSON.parse(localStorage.getItem('usuario'))

    this.colKeys = 'ativo,date,evento,qtd|8,preco,taxas,corretora,irrf,moeda,volume,lucro,qtd_atual,pm_atual,ptax|4'.split(',')
    this.colValues = 'ativo,date,evento,qtd,preco,taxas,corretora,irrf,moeda,volume,lucro,qtd_atual,pm_atual,ptax'.split(',')
    this.table = this.operacoes
    this.formatTable(this.table)

    let corretora = userSettings.calc_por_corretora && row.corretora ? row.corretora : null

    if (!this.operacoes) {
      let operationsFilters = {
        asset: row.ativo,
        broker: corretora,
        start_date: row.date_ini ? DateUtil.dateStringToNgbDatePicker(row.date_ini) : null,
      }

      const fxOriginAsset = this._getSubFxAsset(row)

      this._operationsService.getOperations(operationsFilters, 1, 100000).subscribe({
        next: async (main) => {
          if (fxOriginAsset) {
            operationsFilters.asset = fxOriginAsset
            this._operationsService.getOperations(operationsFilters, 1, 100000).subscribe({
              next: async (sub) => {
                this.operacoes = main.result.concat(sub.result)
                this.operacoes = this.operacoes.sort(
                  (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
                );
                this.table = this.operacoes
                this.formatTable(this.table)
              },
              error: (err) => { }
            })
          } else {
            this.operacoes = main.result
            this.table = this.operacoes
            this.formatTable(this.table)
          }
        },
        error: (err) => { }
      })
    }
  }

  formatTable(objectTable) {
    this.colValues = this.colValues || Object.keys(objectTable[0])
    this.colKeys = this.colKeys || Object.keys(objectTable[0])

    this.resultDecimals = []
    let resultKeys = []
    for (let i in this.colKeys) {
      let key = this.colKeys[i].split('|')
      resultKeys.push(key[0])
      this.resultDecimals.push(key[1] || 2)
    }
    this.colKeys = resultKeys
  }

  numberValueToString(value: any, pipeProps) {
    if (typeof value === 'number') {
      if (this.resultDecimals[pipeProps] > 4) {
        return value.toString().replace('.', ',')
      }
      return value.toFixed(this.resultDecimals[pipeProps] || 2).replace('.', ',')
    }
    if (value && value.indexOf('-') >= 0 && value.split('-').length == 3 && value.length == 10) {
      return value.split('-').reverse().join('/')
    }
    return value
  }

  setMaxModalHeigth() {
    const windowheigth = window.innerHeight
    let styles = {
      'max-height': (windowheigth - 236) + 'px',
    };
    return styles;
  }

  private _getSubFxAsset(mainAsset): string {
    const groupForeignFxOrigin = this._authService.isToGroupFxAsset()
    let fxOriginAsset = null
    if (groupForeignFxOrigin) {
      const walletData = this._cacheService.getCache('walletObject') || null
      fxOriginAsset = walletData.filter(asset => (asset.ativo == mainAsset.ativo + "_E") && (asset.corretora == mainAsset.corretora))

      if (fxOriginAsset.length > 0) {
        return fxOriginAsset[0].ativo
      }
    }

    return null
  }
}
