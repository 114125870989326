import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PermissionEnum } from 'src/app/enums/permission-enum';
import { AuthService } from 'src/app/services/auth.service';
import { CacheService } from 'src/app/services/cache.service';
import { UserService } from 'src/app/services/user.service';
import { WalletService } from 'src/app/services/wallet.service';
import FilterUtil from 'src/app/util/filter-util';

export const modalCentralNotificacoesOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-central-notificacoes' };

@Component({
  selector: 'app-modal-central-notificacoes',
  templateUrl: './modal-central-notificacoes.component.html',
  styleUrls: ['./modal-central-notificacoes.component.scss']
})
export class ModalCentralNotificacoesComponent implements OnInit {

  public modalProps: any = {}

  public notifications: any = []
  public doneNotifications: any = []
  public undoneNotifications: any = []

  public activeTable: string = 'naolidos'
  public hasChange: string = ''
  public hasUserEventPermission: boolean = false
  public selectAllOptions: any = { lidos: false, naolidos: false }

  public userEventsLocal: any = {}
  public userEmailConfig: any = { in_plano: false, in_promocao: false }

  public page: number = 1;
  public pageSize: number = 30;
  public collectionSize: number;

  constructor(
    public activeModal: NgbActiveModal,
    private _walletService: WalletService,
    private _authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private _cacheService: CacheService,
    private _userService: UserService,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.userEventsLocal = this._cacheService.getCache('eventsNewStatus') || {}
    this.hasUserEventPermission = this._authService.hasUserPermission(PermissionEnum.NEWS)

    // e-mail
    this.userEventsLocal.plano = this.userEventsLocal.plano || { delete: true }
    this.userEventsLocal.promocao = this.userEventsLocal.promocao || { delete: true }
    // sistema
    this.userEventsLocal.versao = this.userEventsLocal.versao || { delete: true }
    this.userEventsLocal.alerta = this.userEventsLocal.alerta || { delete: true }
    this.userEventsLocal.aviso = this.userEventsLocal.aviso || { delete: true }
    // eventos
    this.userEventsLocal.proventos = this.userEventsLocal.proventos || { delete: true }
    this.userEventsLocal.eventos = this.userEventsLocal.eventos || { delete: true }
    this.userEventsLocal.subscricao = this.userEventsLocal.subscricao || { delete: true }
    // noticias
    this.userEventsLocal.fatosrelevantes = this.userEventsLocal.fatosrelevantes || { delete: true }
    this.userEventsLocal.comunicadosavisos = this.userEventsLocal.comunicadosavisos || { delete: true }
    this.userEventsLocal.informesrelatorios = this.userEventsLocal.informesrelatorios || { delete: true }
    this.userEventsLocal.atas = this.userEventsLocal.atas || { delete: true }
    this.userEventsLocal.noticias = this.userEventsLocal.noticias || { delete: true }
    this.calcNotifications()

    if (this.modalProps.tab) {
      this.changeTable(this.modalProps.tab)
    }
  }

  getMsgIcon(type) {
    let iconTypes = {
      // e-mail
      plano: 'fas fa-file-signature text-gray',
      promocao: 'fas fa-comments-dollar text-info',
      // sistema
      versao: 'fas fa-code-branch text-green',
      alerta: 'fas fa-exclamation-triangle text-red',
      aviso: 'fas fa-info-circle text-info',
      // eventos
      proventos: 'fas fa-coins',
      eventos: 'fas fa-calendar-check text-warning',
      subscricao: 'fas fa-file-invoice text-purple',
      // noticias
      fatosrelevantes: 'fas fa-newspaper text-blue',
      comunicadosavisos: 'fas fa-newspaper text-blue',
      informesrelatorios: 'fas fa-newspaper text-blue',
      atas: 'fas fa-newspaper text-blue',
      noticias: 'fas fa-newspaper text-blue',
    }
    return iconTypes[type] || ""
  }

  changeTable(selectTable) {
    if (selectTable == 'email') {
      this.getEmailConfigOptions()
    }

    this.activeTable = selectTable
    this.notifications = this.activeTable == 'naolidos' ?
      FilterUtil.lodash().orderBy(this.undoneNotifications, ['date'], ['desc']) :
      FilterUtil.lodash().orderBy(this.doneNotifications, ['date'], ['desc'])

    this.page = 1
    this.collectionSize = this.notifications.length
  }

  selectAllNotifications() {
    this.selectAllOptions[this.activeTable] = !this.selectAllOptions[this.activeTable]
    this.notifications.forEach(el => {
      el.select = this.selectAllOptions[this.activeTable]
    });
  }

  doNotificationsAction(notifications, action) {
    this.hasChange = 'systemNotificationChange'
    let selected = FilterUtil.lodash().filter(notifications, (o) => {
      if (o.select && 'versao,alerta,aviso'.indexOf(o.type) < 0) { this.hasChange = 'stockNotificationChamge' }
      return (o.select)
    })
    selected = !selected.length && notifications?.hash ? [notifications] : selected

    let status = true
    if (action == 'undone') {
      action = 'done'
      status = false
    }

    selected.forEach(el => {
      el.select = false
      el[action] = status
      if (this.userEventsLocal[el.hash]) {
        this.userEventsLocal[el.hash][action] = status
      } else {
        this.userEventsLocal[el.hash] = {}
        this.userEventsLocal[el.hash][action] = status
      }
    })

    if (!selected.length) { return }
    this._cacheService.setCache({ 'eventsNewStatus': this.userEventsLocal })
    this._walletService.addEventsNewsOptions(selected).subscribe(walletData => { })
    this.calcNotifications()
  }

  calcNotifications() {
    this.doneNotifications = []
    this.undoneNotifications = []
    let notifications: any = FilterUtil.lodash().uniqBy(this.modalProps.data, 'hash')

    notifications.forEach(o => {
      o.hash = o.hash || FilterUtil.MD5(JSON.stringify(o))
      o.done = false
      o.delete = false
      if (this.userEventsLocal[o.hash]) {
        o.done = this.userEventsLocal[o.hash].done
        o.delete = this.userEventsLocal[o.hash].delete
      }

      if (o.delete) { } else if (o.done) {
        this.doneNotifications.push(o)
      }
      else if (!o.done) {
        this.undoneNotifications.push(o)
      }
    });

    this.modalProps.data = this.undoneNotifications.concat(this.doneNotifications)

    if (this.activeTable == 'naolidos') {
      this.notifications = this.undoneNotifications
    }
    else if (this.activeTable == 'lidos') {
      this.notifications = this.doneNotifications
    }
    this.collectionSize = this.notifications.length
  }

  saveEventNewsOptions() {
    let result = [
      // e-mail
      { hash: "plano", delete: this.userEventsLocal.plano.delete },
      { hash: "promocao", delete: this.userEventsLocal.promocao.delete },
      // sistema
      { hash: "versao", delete: this.userEventsLocal.versao.delete },
      { hash: "alerta", delete: this.userEventsLocal.alerta.delete },
      { hash: "aviso", delete: this.userEventsLocal.aviso.delete },
      // eventos
      { hash: "proventos", delete: this.userEventsLocal.proventos.delete },
      { hash: "eventos", delete: this.userEventsLocal.eventos.delete },
      { hash: "subscricao", delete: this.userEventsLocal.subscricao.delete },
      // noticias
      { hash: "fatosrelevantes", delete: this.userEventsLocal.fatosrelevantes.delete },
      { hash: "comunicadosavisos", delete: this.userEventsLocal.comunicadosavisos.delete },
      { hash: "informesrelatorios", delete: this.userEventsLocal.informesrelatorios.delete },
      { hash: "atas", delete: this.userEventsLocal.atas.delete },
      { hash: "noticias", delete: this.userEventsLocal.noticias.delete },
    ]
    this._cacheService.setCache({ 'eventsNewStatus': this.userEventsLocal })
    this.spinnerService.show()
    this._walletService.addEventsNewsOptions(result).subscribe({
      next: (res) => {
        this.spinnerService.hide()
        this.activeModal.close('changeOptions')
      },
      error: (err) => {
        this.spinnerService.hide()
      }
    })
  }

  saveEmailConfigOptions() {
    this.spinnerService.show()
    this._userService.updateEmailConfig(this.userEmailConfig).subscribe({
      next: (res) => {
        this.spinnerService.hide()
        this._cacheService.setCache({ 'emailConfig': this.userEmailConfig })
        this._toastr.success('Configurações de notificações por email atualizadas com sucesso.')
        this.activeModal.close('changeOptions')
      },
      error: (err) => {
        this.spinnerService.hide()
      }
    })
  }

  getEmailConfigOptions() {
    if (this._cacheService.getCache('emailConfig')) {
      this.userEmailConfig = this._cacheService.getCache('emailConfig')
    } else {
      this.spinnerService.show()
      this._userService.getEmailConfig().subscribe({
        next: async (emailConfig) => {
          this._cacheService.setCache({ 'emailConfig': emailConfig })
          this.userEmailConfig = emailConfig
          this.spinnerService.hide()
        },
        error: (err) => { this.spinnerService.hide() }
      })
    }
  }

  // Styles
  setMaxModalHeigth() {
    const windowheigth = window.innerHeight
    let styles = {
      'max-height': (windowheigth - 236) + 'px',
    };
    return styles;
  }

  titleStyle(notification) {
    let unreadNotificationStyle = {
      "font-weight": 700,
    }
    let readNotificationStyle = {
      "font-weight": 400,
    }
    return !notification.done ? unreadNotificationStyle : readNotificationStyle
  }

  setSelectedStyle(notification) {
    let styles = {
      'background-color': 'rgb(235, 235, 235)',
    };
    if (notification.select) {
      return styles
    }
    return '';
  }

}
