import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { PermissionEnum } from '../enums/permission-enum';
import DateUtil from '../util/date-util';
import FilterUtil from '../util/filter-util';
import NumberUtil from '../util/number-util';
import StaticParams from '../util/static-params';
import { AuthService } from './auth.service';
import { BaseApiService } from './base-api.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseApiService {

  public behaviorNotifications: BehaviorSubject<any>;

  constructor(
    protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService,
    private _authService: AuthService,
    private _cacheService: CacheService,
  ) {
    super(baseApi, router, toastr);
    this.behaviorNotifications = new BehaviorSubject(null);
  }

  searchEventsAndNewsByList(hashList) {
    return this.post(this.urlBaseApi + `/event/search/wid/${this.walletId}`, hashList);
  }

  getAtivosMessages() {
    let stockLastPrice = JSON.parse(localStorage.getItem('stockData')) || {}
    let notifications = []
    let hashList = []

    let stockKeys = Object.keys(stockLastPrice)
    let stockEvents = []
    let stockNews = []

    let currencySymbols = StaticParams.currencySymbols

    stockKeys.forEach(el => {
      if (stockLastPrice[el]?.events?.cashDividends) {
        stockLastPrice[el]?.events?.cashDividends.forEach(element => {
          element.ativo = el
        });
        stockEvents = stockEvents.concat(stockLastPrice[el]?.events?.cashDividends)
      }
      if (stockLastPrice[el]?.events?.stockDividends) {
        stockLastPrice[el]?.events?.stockDividends.forEach(element => {
          element.ativo = el
        });
        stockEvents = stockEvents.concat(stockLastPrice[el]?.events?.stockDividends)
      }
      if (stockLastPrice[el]?.events?.subscriptions) {
        stockLastPrice[el]?.events?.subscriptions.forEach(element => {
          element.ativo = el
        });
        stockEvents = stockEvents.concat(stockLastPrice[el]?.events?.subscriptions)
      }
      if (stockLastPrice[el]?.news?.length) {
        stockLastPrice[el]?.news?.forEach(element => {
          element.ativo = el
        });
        stockNews = stockNews.concat(stockLastPrice[el]?.news)
      }
    });

    stockEvents.forEach(el => {
      let hash = el.hash || null
      let date = el.approvedOn ? el.approvedOn.split('/') : el.lastDatePrior.split('/')
      date = date[2] + '-' + date[1] + '-' + date[0]
      let ativo = el.isinCode ? el.isinCode.substring(2, 6) : el.ativo
      let titulo = ''
      let mensagem = ''
      let type = ''
      let url = null

      el.percentage = NumberUtil.brFormat(el.percentage)
      el.priceUnit = NumberUtil.brFormat(el.priceUnit)
      el.factor = NumberUtil.brFormat(el.factor)
      el.rate = NumberUtil.brFormat(el.rate)
      let currencySymbol = currencySymbols[el.currency] || 'R$'
      let lastDatePrior = el.lastDatePrior ? ` Data Com ${el.lastDatePrior}.` : ''
      let relatedTo = el.relatedTo ? ` Relativo a ${el.relatedTo || '-'}.` : ''
      let approvedOn = el.approvedOn ? ` Aprovado em ${el.approvedOn || '-'}.` : ''
      let paymentDate = el.paymentDate ? ` Previsão de pagamento em ${el.paymentDate || '-'}.` : ''

      switch (el.label) {
        case "SUBSCRICAO":
          titulo = `${ativo} | Subscrição`
          mensagem = `- Fator de ${el.percentage}%. Valor unitário de ${currencySymbol} ${el.priceUnit}.${approvedOn}${lastDatePrior} Subscrição até: ${el.subscriptionDate}. Período de negociação: ${el.tradingPeriod}.`
          mensagem += `\n- Como lançar no sistema =>`
          url = 'https://www.dlombelloplanilhas.com/faq/faq-uso-dos-sistemas'
          type = 'subscricao'
          break
        case "BONIFICACAO":
          titulo = `${ativo} | Bonificação`
          mensagem = `- Fator de ${el.factor}%.${approvedOn}${lastDatePrior}`
          mensagem += `\n- Como lançar no sistema =>`
          url = 'https://www.dlombelloplanilhas.com/faq/faq-uso-dos-sistemas'
          type = 'eventos'
          break
        case "GRUPAMENTO":
          titulo = `${ativo} | Grupamento`
          mensagem = `- Fator de ${el.factor}.${approvedOn}${lastDatePrior}`
          mensagem += `\n- Como lançar no sistema =>`
          url = 'https://www.dlombelloplanilhas.com/faq/faq-uso-dos-sistemas'
          type = 'eventos'
          break
        case "DESDOBRAMENTO":
          titulo = `${ativo} | Desdobramento`
          mensagem = `- Fator de ${el.factor}.${approvedOn}${lastDatePrior}`
          mensagem += `\n- Como lançar no sistema =>`
          url = 'https://www.dlombelloplanilhas.com/faq/faq-uso-dos-sistemas'
          type = 'eventos'
          break
        default:
          titulo = `${ativo} | Provento`
          mensagem = `- ${el.label}. Valor unitário de ${currencySymbol} ${el.rate}.${approvedOn}${lastDatePrior}${paymentDate}${relatedTo}`
          type = 'proventos'
      }

      hashList.push(hash)
      notifications.push({ hash, date, titulo, mensagem, url, type })
    })

    stockNews.forEach(el => {
      let titulo = el.title
      try {
        let title = el.title || ''
        title = el.title.split(') ')[1].trim().split('?').join('-').split('  ').join(' ')
        title = title.substring(0, 1) == '-' ? title.replace('-', '').trim() : title
        titulo = `${el.title.split(')')[0] + ")"} | ${title}`
      } catch (e) { }
      let hash = el.hash || null
      let date = el.url.split("dataNoticia=")[1].split(' ')[0]
      let mensagem = '- Plantão de Notícias B3.'
      let url = el.url
      let type = this.getNoticiaType(el.title)

      hashList.push(hash)
      notifications.push({ hash, date, titulo, mensagem, url, type })
    })
    return { notifications, hashList }
  }

  getVersionMessages() {
    let hashList = []
    let notifications = JSON.parse(JSON.stringify(StaticParams.versionNotifications)) || []

    notifications.forEach(o => {
      o.hash = o.hash || FilterUtil.MD5(JSON.stringify(o))
      o.titulo = `${o.titulo} | Atualização de versão`
      o.done = false
      o.delete = false
      o.type = 'versao'
      hashList.push(o.hash)
    })
    return { notifications, hashList }
  }

  getSystemMessages() {
    let hashList = []
    let notifications = this._getPeriodicalMessages().concat(this._cacheService.getCache('systemNotifications') || [])

    notifications.forEach(o => {
      o.hash = o.hash || FilterUtil.MD5(JSON.stringify(o))
      o.titulo = `${o.titulo}`
      o.done = false
      o.delete = false
      o.type = o.type || 'alerta'
      hashList.push(o.hash)
    })
    return { notifications, hashList }
  }

  private _getPeriodicalMessages() {
    let notification = []
    let hoje = DateUtil.dateToString(new Date()).split('-')
    let mesPassado = DateUtil.dateToString(new Date(parseInt(hoje[0]), parseInt(hoje[1]) - 1, 0))
    mesPassado = mesPassado.split('-')

    notification.push({
      date: `${hoje[0]}-${hoje[1]}-01`,
      titulo: `Aviso | Atualização mensal de dados `,
      mensagem: `- Não esqueça de atualizar as operações, proventos e saldo referênte à ${mesPassado[1]}/${mesPassado[0]}.
- Ná página Configuração/Extratos você pode importar do XLS da B3 ou de seu banco ou corretora.
- Investidor B3.`,
      url: 'https://www.investidor.b3.com.br',
      type: 'aviso',
    })

    if (parseInt(hoje[2]) >= 23) {
      notification.push({
        date: `${hoje[0]}-${hoje[1]}-23`,
        titulo: `Alerta | Pagamento de DARF/GCAP`,
        mensagem: `- Últimos dias para pagamento de DARF das operações referente à ${mesPassado[1]}/${mesPassado[0]}.
- Verifique se tem algum valor à pagar na página DARF.`,
        url: '/darf',
        type: 'alerta',
      })
    }

    let usuario = JSON.parse(localStorage.getItem('usuario'))
    let today = new Date();
    let vencimentoPlano = usuario?.alpha?.data_vencimento
    let vencimentoPlanoDate = vencimentoPlano ? new Date(vencimentoPlano) : null
    if (vencimentoPlanoDate) {
      vencimentoPlanoDate.setMonth(vencimentoPlanoDate.getMonth() - 1)
    }

    if (!vencimentoPlanoDate) {
      notification.push({
        date: `${hoje[0]}-${hoje[1]}-01`,
        titulo: `Alerta | Usuário sem plano ativo`,
        mensagem: `- Olá! Você ainda não tem um plano ativo. Está gostando do nosso sistema?
- Assine um agora mesmo na página Planos.`,
        url: '/alpha',
        type: 'alerta',
      })
    }
    else if (vencimentoPlanoDate && today >= vencimentoPlanoDate && today < new Date(vencimentoPlano)) {
      notification.push({
        date: DateUtil.dateToString(vencimentoPlanoDate),
        titulo: `Alerta | Vencimento de Plano `,
        mensagem: `- Seu plano atual vence em ${DateUtil.usStrDatetoBrStrDate(vencimentoPlano)}.
- Renove agora mesmo na página Planos.`,
        url: '/alpha',
        type: 'alerta',
      })
    }
    else if (vencimentoPlanoDate && today >= new Date(vencimentoPlano)) {
      notification.push({
        date: vencimentoPlano,
        titulo: `Alerta | Vencimento de Plano `,
        mensagem: `- Seu plano venceu em ${DateUtil.usStrDatetoBrStrDate(vencimentoPlano)}.
- Assine um agora mesmo na página Planos.`,
        url: '/alpha',
        type: 'alerta',
      })
    }

    return notification
  }

  getNoticiaType(titulo) {
    titulo = titulo ? titulo.toUpperCase() : ''
    if (titulo.indexOf('COMUNICADO') >= 0 || titulo.indexOf('AVISO') >= 0) { return 'comunicadosavisos' }
    if (titulo.indexOf('INFORME') >= 0 || titulo.indexOf('RELATORIO') >= 0 || titulo.indexOf('PRESS-RELEASE') >= 0) { return 'informesrelatorios' }
    if (titulo.indexOf('FATO RELEVANTE') >= 0) { return 'fatosrelevantes' }
    if (titulo.indexOf('ATA ') >= 0) { return 'atas' }
    return 'noticias'
  }

  removeNotificacoes(object, attribute) {
    return FilterUtil.lodash().filter(object, (o) => {
      return (o.type != attribute)
    })
  }

  async calcUnreadAndNotifications() {
    let notifications = []
    let unreadNotifications = []

    let eventsNewStatus = this._cacheService.getCache('eventsNewStatus')
    while (!eventsNewStatus) {
      eventsNewStatus = this._cacheService.getCache('eventsNewStatus')
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    // sisetma
    let versionNotifications = this.getVersionMessages().notifications
    let systemNotifications = this.getSystemMessages().notifications
    let versionAllNotifications = FilterUtil.lodash().filter(systemNotifications.concat(versionNotifications), (o) => { return (o.type == 'versao') })

    let today = new Date()
    today.setMonth(today.getMonth() - 1)
    today = DateUtil.dateToString(today)

    let showVersao = eventsNewStatus?.versao?.delete == false ? eventsNewStatus?.versao?.delete : true
    let showAlerta = eventsNewStatus?.alerta?.delete == false ? eventsNewStatus?.alerta?.delete : true
    let showAviso = eventsNewStatus?.aviso?.delete == false ? eventsNewStatus?.aviso?.delete : true

    let systemAllNotifications = FilterUtil.lodash().filter(versionNotifications.concat(systemNotifications), (o) => { return o.date >= today })
    if (showVersao == false) { systemAllNotifications = this.removeNotificacoes(systemAllNotifications, 'versao') }
    if (showAlerta == false) { systemAllNotifications = this.removeNotificacoes(systemAllNotifications, 'alerta') }
    if (showAviso == false) { systemAllNotifications = this.removeNotificacoes(systemAllNotifications, 'aviso') }

    notifications = systemAllNotifications

    if (this._authService.hasUserPermission(PermissionEnum.NEWS)) {
      // noticias
      let showFatosrelevantes = eventsNewStatus?.fatosrelevantes?.delete == false ? false : true
      let showComunicadosavisos = eventsNewStatus?.comunicadosavisos?.delete == false ? false : true
      let showInformesrelatorios = eventsNewStatus?.informesrelatorios?.delete == false ? false : true
      let showAtas = eventsNewStatus?.atas?.delete == false ? false : true
      let showNoticias = eventsNewStatus?.noticias?.delete == false ? false : true
      // eventos
      let showEventos = eventsNewStatus?.eventos?.delete == false ? false : true
      let showSubscricao = eventsNewStatus?.subscricao?.delete == false ? false : true
      let showProventos = eventsNewStatus?.proventos?.delete == false ? false : true

      let ativosAllNotifications = this.getAtivosMessages().notifications
      // noticias
      if (showNoticias == false) { ativosAllNotifications = this.removeNotificacoes(ativosAllNotifications, 'noticias') }
      if (showAtas == false) { ativosAllNotifications = this.removeNotificacoes(ativosAllNotifications, 'atas') }
      if (showComunicadosavisos == false) { ativosAllNotifications = this.removeNotificacoes(ativosAllNotifications, 'comunicadosavisos') }
      if (showInformesrelatorios == false) { ativosAllNotifications = this.removeNotificacoes(ativosAllNotifications, 'informesrelatorios') }
      if (showFatosrelevantes == false) { ativosAllNotifications = this.removeNotificacoes(ativosAllNotifications, 'fatosrelevantes') }
      // eventos
      if (showProventos == false) { ativosAllNotifications = this.removeNotificacoes(ativosAllNotifications, 'proventos') }
      if (showSubscricao == false) { ativosAllNotifications = this.removeNotificacoes(ativosAllNotifications, 'subscricao') }
      if (showEventos == false) { ativosAllNotifications = this.removeNotificacoes(ativosAllNotifications, 'eventos') }

      notifications = notifications.concat(ativosAllNotifications)
    }

    let validNotifications = []
    notifications.forEach(o => {
      o.hash = o.hash || FilterUtil.MD5(JSON.stringify(o))
      o.done = false
      o.delete = false
      if (eventsNewStatus[o.hash]) {
        o.done = eventsNewStatus[o.hash].done
        o.delete = eventsNewStatus[o.hash].delete
      }
      if (!o.delete) {
        validNotifications.push(o)
      }
    });

    notifications = FilterUtil.lodash().orderBy(validNotifications, ['date'], ['desc'])
    unreadNotifications = FilterUtil.lodash().filter(notifications, (o) => { return (!o.done) })
    this.behaviorNotifications.next({ notifications, unreadNotifications, versionAllNotifications })
  }
}
