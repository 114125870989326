import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseApiService {

  constructor(
    protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService
  ) {
    super(baseApi, router, toastr);
  }

  linkPayment(payment_id: string, callback: boolean): Observable<any> {
    return this.put(this.urlBaseApi + `/payment/link/${payment_id}/${callback}`, '');
  }

  unlinkAlphaPayment(payment_id: string): Observable<any> {
    return this.delete(this.urlBaseApi + '/payment/unlink/' + payment_id, '');
  }

  createPixPayment(plano: string): Observable<any> {
    return this.post(this.urlBaseApi + '/payment/pix', {'cd_plano': plano});
  }
}
